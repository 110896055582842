import {LeftMenu} from "./parts/leftmenu";
import {Header} from "./parts/header";
import React, {ReactNode, useEffect, useState} from "react";
import {useGlobalSelector} from "../stores/global.hooks";
import {Loading} from "../components/Loading";

export const PageTemplate = ({children, valKey}: { children: ReactNode; valKey: string; }) => {
    const globals = useGlobalSelector(state => state.globals);

    return (
        <>
            <div key={"pageTemplate"} style={{display: "flex"}}>
                <div className={"left__team__menu no_scrollbars"} style={{
                    height: window.innerHeight,
                    padding: 0,
                    margin: 0,
                    zIndex: 999,
                    overflowY: "scroll",
                    overflowX: 'hidden'
                }}>
                    <LeftMenu/>
                </div>
                <div style={{marginLeft: 80, position: 'relative'}}>
                    <Header/>

                    <main className={(globals.loading)?"change-enter":"change-enter-active"}>
                        <div key={valKey}>{children}</div>
                    </main>

                    <footer>
                    </footer>
                </div>

            </div>
        </>
    )
}
import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {getCategories, getCategory} from "../services/APIRequests";
import CategoryDE from "../services/API/CategoryDE";

export const categorySlice = createSlice({
    name: 'global',
    initialState: {
        checked: false,
        categories: {} as {[key:string]: CategoryDE},
        categoryList: [] as string[],
        selected: "",
    },
    reducers: {
        populateCategories: (state, action: PayloadAction<CategoryDE[]>) => {
            state.checked = true;
            if(action.payload) {
                state.categories = {};
                state.categoryList = [];
                for (let i = 0; i < action.payload.length; i++) {
                    state.categories[action.payload[i].key] = action.payload[i];
                    state.categoryList.push(action.payload[i].key);
                }
            }
        },
        updateCategoryData: (state, action: PayloadAction<CategoryDE>) => {
            state.checked = true;
            if(action.payload) {
                if(!state.categories[action.payload.key]){
                    state.categories[action.payload.key] = action.payload;
                }else {
                    state.categories[action.payload.key].data = action.payload.data;
                    state.categories[action.payload.key].modified = action.payload.modified;
                    state.categories[action.payload.key].version = action.payload.version;
                }
            }
        },
        selectCategory: (state, action: PayloadAction<string>) => {
            if(action.payload) {
                state.selected = action.payload;
            }
        }
    },
});

export async function updateCategoryRequest(teamKey: string, category: string, dispatch: Dispatch) : Promise<{ }>{
    const response = await getCategory(teamKey, category);
    dispatch({
        type: updateCategoryData.type,
        payload: response
    });
    return {};
}



export async function populateCategoriesRequest(teamKey: string, dispatch: Dispatch) : Promise<{ }>{
    const response = await getCategories(teamKey);
    dispatch({
        type: populateCategories.type,
        payload: response
    });
    return {};
}

export const {populateCategories, selectCategory, updateCategoryData} = categorySlice.actions;

export default categorySlice.reducer;

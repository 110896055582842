import React from "react";
import {hostName} from "../services/APIRequests";

export function LoginPage() {
    return (
        <div className="signup">
            <main className="signup__main">
                <div className="container">
                    <div className="signup__container">
                        <div className="signup__logo">
                            <a href="#"><img src="/images/logo.png" alt="logo" />Brainstorm</a>
                        </div>

                        <div className="signup__head">
                            <h3>Login to Brainstorm</h3>
                            <p>Login using your Github, Google, Microsoft accounts, after which you can create/join
                                teams.</p>
                        </div>
                        <a href={`https://${hostName}/oauth2/authorization/google`}
                           className="signup__btn-create btn btn--type-01"><i className="icon-google" aria-hidden="true"></i> Sign in with
                            Google</a><br/><br/>
                        <a href={`https://${hostName}/oauth2/authorization/github`}
                           className="signup__btn-create btn btn--type-01"><i className="icon-github" aria-hidden="true"></i> Sign in with
                            Github</a><br/><br/>
                        <a href={`https://${hostName}/oauth2/authorization/azure`}
                           className="signup__btn-create btn btn--type-01"><i className="icon-microsoft" aria-hidden="true"></i> Sign in with
                            Microsoft</a><br/><br/>

                    </div>
                </div>
            </main>

            <footer className="signup__footer">
                <div className="container">
                    <div className="signup__footer-content">
                        <ul className="signup__footer-menu">
                            <li><a href="/tos">TOS</a></li>
                            <li><a href="/privacy">Privacy</a></li>
                            <li><a href="/team/feedback">Send Feedback</a></li>
                        </ul>
                    </div>
                </div>
            </footer>
        </div>

    );
}

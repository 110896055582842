import {useGlobalDispatch, useGlobalSelector} from "../stores/global.hooks";
import {useEffect, useState} from "react";
import {setSubtitle, setTitle} from "../stores/global.reducer";
import {useLoaderData, useNavigate} from "react-router-dom";
import InviteMeta from "../services/API/InviteMeta";
import {generateMarkdown} from "./CreateTeam";
import {acceptInvite} from "../services/APIRequests";
import TeamDE from "../services/API/TeamDE";
import {PageTemplate} from "./page";

export const InvitePage = () => {
    const navigate = useNavigate();
    const dispatch = useGlobalDispatch();
    useEffect(()=>{
        dispatch({
            type: setTitle.type,
            payload: "Team Invitation"
        });
        dispatch({
            type: setSubtitle.type,
            payload: ""
        });
    }, []);

    const data = useLoaderData() as string|TeamDE|InviteMeta;

    if(data === undefined){
        return <div>NOT FOUND</div>;
    }
    if(typeof data == 'string'){
        return (
            <PageTemplate key={"page"} valKey={`invitePage`}>
                <div className="container" style={{flexDirection:"column"}}>
                    <div style={{display:"flex", flexDirection:"row", paddingTop:"20%"}}>
                        <div style={{flexGrow:"1"}}></div>
                        <div style={{display:"flex", flexDirection:"column", background:"#fff", borderRadius:10, padding: 10}}>
                            <div style={{margin:0}}><h2 style={{fontSize:22, color:"#7a7a7a", margin:0}}><i>{data}</i></h2></div>
                        </div>
                        <div style={{flexGrow:"1"}}></div>
                    </div>
                </div>
            </PageTemplate>
        );
    }else if(data !=null && "key" in data && data?.key != null){
        document.location.href = "/team/"+data.key
        return <></>;
    }
    const invite = data as InviteMeta;
    let avatarImage: JSX.Element;
    if(invite.team.data.icon && invite.team.data.icon.url){
        avatarImage = <div><img src={invite.team.data.icon.url} style={{width:"50%", margin:20}} /></div>;
    }else{
        avatarImage = <div><img src={"/fonts/icons/avatars/"+invite.team.data.name[0]?.toUpperCase()+".svg"} style={{width:"50%", margin:20}} /></div>;
    }
    return (
        <PageTemplate key={"page"} valKey={`invitePage-${invite.invite.key}`}>
            <div className="container" style={{flexDirection:"column"}}>
                <div style={{display:"flex", flexDirection:"row", paddingTop:"20%"}}>
                    <div style={{flexGrow:"1"}}></div>
                    <div style={{display:"flex", flexDirection:"column", background:"#fff", border:"1px solid #f0f0f0", borderRadius:10, padding: 10}}>
                        <div style={{margin:0}}><h2 style={{fontSize:22, color:"#7a7a7a", margin:20, marginBottom:20}}><span style={{fontSize:20, fontWeight:"normal"}}>You are invited to join the team</span> <i>"{invite.team.data.name}"</i></h2></div>
                        <div style={{display:"flex", flexDirection:"row"}}>
                            <div style={{width:400, textAlign:"center", paddingRight: 20, borderRight:"1px solid #f0f0f0", display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                <div style={{flexGrow:"1"}}/>
                                {avatarImage}
                                <a onClick={async ()=>{
                                    const returned = await acceptInvite(invite.invite.key);
                                    if(typeof returned == 'string') {
                                        alert(returned);
                                    }else if(returned){
                                        document.location.href = "/team/"+returned.key
                                    }
                                }} style={{width:"100%", padding:"20px", borderRadius:20, cursor:"pointer"}} className={"btn btn--type-02"}>Join Team</a>
                                <div><a onClick={()=>navigate("/")} className={""}>Reject invitation</a></div>
                            </div>
                            <div style={{flexGrow:1, width:400, marginLeft:10}}>{generateMarkdown(invite.team.data.description)}</div>
                        </div>
                    </div>
                    <div style={{flexGrow:"1"}}></div>

                </div>
            </div>
        </PageTemplate>
    );
}

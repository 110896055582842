import {DefaultPermissionType, PermissionType} from "./type";
import {PermissionTree} from "../../services/PermissionTree";

export const createRole = {
    perm: "team.permission.role.create",
    depends: [],
    description: "Ability for a user to create roles.",
    title: "Create Roles",
    admin: "team.admin.permission.role.create",
    check(_, {tree, roles}): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const grantRole ={
    perm: "team.permission.role.grant",
    depends: [],
    description: "Ability for a user to grant permissions to roles.",
    title: "Grant Permissions",
    admin: "team.admin.permission.role.grant",
    check(_, {tree, roles}): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const denyRole = {
    perm: "team.permission.role.deny",
    depends: [],
    description: "Ability for a user to deny permissions to roles.",
    title: "Deny Permissions",
    admin: "team.admin.permission.role.deny",
    check(_, {tree, roles}): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const updateRole = {
    perm: "team.permission.role.update",
    depends: [],
    description: "Ability for a user to update roles name.",
    title: "Update Role",
    admin: "team.admin.permission.role.update",
    check(_, {tree, roles}): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const modifyRoles = {
    check(_, permission): boolean {
        const checkRoles: DefaultPermissionType[] = [
            updateRole,
            grantRole,
            denyRole,
            createRole
        ];
        for (let i = 0; i < checkRoles.length; i++) {
            if(checkRoles[i].check(null, permission)) return true;
        }
        return false;
    }

} as DefaultPermissionType;


export const rolePerms = {
    createRole,
    grantRole,
    denyRole,
    updateRole
};
import {useGlobalSelector} from "../stores/global.hooks";
import ReactTimeAgo from 'react-time-ago'
import {useNavigate} from "react-router-dom";


export const IdeaListItem = (props: {ideaKey: string, type: string}) => {
    const navigate = useNavigate();
    const categories = useGlobalSelector(state=>state.categories);
    const categoryData = categories.categories[categories.selected];
    const teamInfo = useGlobalSelector(state=>state.teams);
    const ideas = useGlobalSelector(state=>state.ideas);
    const ideaData = ideas.ideas[props.ideaKey];
    if(!ideaData) return <></>
    return (
        <div key={ideaData.idea.idea.key} className="posts__item" style={{width:"100%", borderBottom:"1px solid #f0f0f0"}}>
            <div style={{width:"100%", display:"flex", flexDirection:"row", paddingLeft:20, paddingRight:20, fontSize:14}}>
                <div style={{width:"60px", textAlign:"center", display:"inline-block"}}>
                    {!ideaData.idea.idea.data.decided?"":<i className={"fa fa fa-check-square"}/>}
                </div>
                <div style={{flexGrow:"1"}}>
                    <a style={{color:"#424242", fontSize:18, display:"inline-block"}} onClick={()=>navigate("/team/"+teamInfo.teams[teamInfo.selected].team.key+"/category/"+categoryData.key+"/idea/"+props.ideaKey+"/")}>
                        {ideaData.idea.idea.data.title}
                    </a><br/>
                    <p>{ideaData.idea.idea.data.summary}</p>
                </div>
                <div style={{width:"100px", textAlign:"center", display:"inline-block"}}>
                    {ideaData.idea.voteResults.upPercent*100}%
                </div>
                <div style={{width:"100px", textAlign:"center", display:"inline-block"}}>
                    {ideaData.idea.voteResults.downPercent*100}%
                </div>
                <div style={{width:"100px", textAlign:"center", display:"inline-block"}}>
                    {ideaData.idea.voteResults.totalMembers}
                </div>
                <div style={{width:"100px",  textAlign:"center", display:"inline-block"}}>
                    {ideaData.idea.voteResults.percentVoted*100}%
                </div>

                <div style={{width:"80px", fontSize:10, textAlign:"right", display:"inline-block"}}>
                    <ReactTimeAgo date={new Date(ideaData?.idea?.idea?.created)} locale="en-US"/>
                </div>
                <div style={{width:"80px", fontSize:10, textAlign:"right", display:"inline-block"}}>
                    {ideaData?.idea?.idea?.modified?<ReactTimeAgo date={new Date(ideaData?.idea?.idea?.modified)} locale="en-US"/>:""}
                </div>
            </div>
        </div>
    );
}

import {useGlobalDispatch, useGlobalSelector} from "../stores/global.hooks";
import {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {setSubtitle, setTitle} from "../stores/global.reducer";
import {createCategory, createTeam, updateCategory} from "../services/APIRequests";
import Checkbox from '@uiw/react-checkbox';
import { filterRoles} from "../services/PermissionTree";
import {PageTemplate} from "./page";
import {useNavigate} from "react-router-dom";


export const EditCategoryPage = () => {
    const navigate = useNavigate();
    const dispatch = useGlobalDispatch();
    useEffect(() => {
        dispatch({
            type: setTitle.type,
            payload: "Create Category"
        });
        dispatch({
            type: setSubtitle.type,
            payload: "Create a new category for "+teamData.team.data.name
        });
    }, []);

    const categoryInfo = useGlobalSelector(state=>state.categories);
    const categoryData = categoryInfo.categories[categoryInfo.selected];

    const [viewRolesNew, setViewRoles] = useState(categoryData.data.viewRoles);
    const [createRolesNew, setCreateRoles] = useState(categoryData.data.createIdeaRoles);
    const [voteRolesNew, setVoteRoles] = useState(categoryData.data.voteIdeaRoles);
    const [commentRolesNew, setCommentRoles] = useState(categoryData.data.commentRoles);
    const [description, setDescription] = useState(categoryData.data.description);
    const [name, setName] = useState(categoryData.data.name);


    const teamInfo = useGlobalSelector(state=>state.teams);
    const teamData = teamInfo.teams[teamInfo.selected];

    const viewIdeaRoles = filterRoles(teamInfo.roles, ["team.permission.idea.view", "team.admin.permission.idea.view"]);
    const listRoles = filterRoles(viewIdeaRoles, ["team.permission.idea.list", "team.admin.permission.idea.list"]);
    const voteRoles = filterRoles(listRoles, ["team.permission.idea.list", "team.admin.permission.idea.list"]);
    const createRoles = filterRoles(listRoles, ["team.permission.idea.create", "team.admin.permission.idea.create"]);
    const commentRoles = filterRoles(listRoles, ["team.permission.idea.comment", "team.admin.permission.idea.comment"]);


    return (
        <PageTemplate key={"page"} valKey={"editCategory"}>
            <div className="container">
                <div className="nav">
                    <div className="nav__thread" style={{justifyContent: "flex-start", width:"100%"}}>
                        <a style={{fontSize: 13, paddingLeft: 10, paddingRight: 10}} onClick={()=>navigate(`/team/${teamData.team.key}/`)} className="">{teamData.team.data.name}</a> /
                        <a style={{fontSize: 13, paddingLeft: 10, paddingRight: 10}} onClick={()=>navigate(`/team/${teamData.team.key}/category/${categoryData.key}/`)} className="">{categoryData.data.name}</a> /
                        <span style={{fontSize: 13, fontStyle:"italic", paddingLeft: 10}}>Edit Category</span>
                    </div>
                </div>
                <div className="create">
                    <div className="create__head">
                        <i className={"icon-list_alt"} style={{color:"#9b9b9b", fontSize: 30}}/> <div className="create__title" style={{paddingLeft:"10px"}}>Edit Category</div>
                        <span></span>
                    </div>
                    <div className="create__section">
                        <label className="create__label" htmlFor="name">Category Name</label>
                        <input type="text" className="form-control" id="name" value={name} onChange={e =>setName(e.target.value)} placeholder="Category name here"/>
                    </div>
                    <div className="create__section create__textarea">
                        <label className="create__label" htmlFor="description">Description</label>
                        <input type="text" className="form-control" id="description" value={description} onChange={e =>setDescription(e.target.value)} placeholder="Category description here"/>
                    </div>
                    <div className="create__section" style={{borderTop:"1px solid #f0f0f0", background:"#fafafa", borderRadius:8, padding:10}}>
                        <label className="create__label">View Roles</label>
                        {viewIdeaRoles.map(roleAndPerm=><Checkbox onChange={(x)=>{
                            const name = roleAndPerm.role.data.name;
                            if(viewRolesNew.includes(name)) setViewRoles(viewRolesNew.filter(r=>r!==name))
                            else setViewRoles([...viewRolesNew, name])
                        }} checked={viewRolesNew.includes(roleAndPerm.role.data.name)}>{roleAndPerm.role.data.name}</Checkbox>)}
                    </div>
                    <div className="create__section" style={{borderTop:"1px solid #f0f0f0", background:"#fafafa", borderRadius:8, padding:10}}>
                        <label className="create__label">Create Roles</label>
                        {createRoles.map(roleAndPerm=><Checkbox onChange={(x)=>{
                            const name = roleAndPerm.role.data.name;
                            if(createRolesNew.includes(name)) setCreateRoles(createRolesNew.filter(r=>r!==name))
                            else setCreateRoles([...createRolesNew, name])
                        }} checked={createRolesNew.includes(roleAndPerm.role.data.name)}>{roleAndPerm.role.data.name}</Checkbox>)}
                    </div>
                    <div className="create__section" style={{borderTop:"1px solid #f0f0f0", background:"#fafafa", borderRadius:8, padding:10}}>
                        <label className="create__label">Comment Roles</label>
                        {commentRoles.map(roleAndPerm=><Checkbox onChange={(x)=>{
                            const name = roleAndPerm.role.data.name;
                            if(commentRolesNew.includes(name)) setCommentRoles(commentRolesNew.filter(r=>r!==name))
                            else setCommentRoles([...commentRolesNew, name])
                        }} checked={commentRolesNew.includes(roleAndPerm.role.data.name)}>{roleAndPerm.role.data.name}</Checkbox>)}
                    </div>
                    <div className="create__section" style={{borderTop:"1px solid #f0f0f0", background:"#fafafa", borderRadius:8, padding:10}}>
                        <label className="create__label">Vote Roles</label>
                        {voteRoles.map(roleAndPerm=><Checkbox onChange={(x)=>{
                            const name = roleAndPerm.role.data.name;
                            if(voteRolesNew.includes(name)) setVoteRoles(voteRolesNew.filter(r=>r!==name))
                            else setVoteRoles([...voteRolesNew, name])
                        }} checked={voteRolesNew.includes(roleAndPerm.role.data.name)}>{roleAndPerm.role.data.name}</Checkbox>)}
                    </div>
                    {name.length>2?<div className="create__footer" style={{display: "block", textAlign: "right"}}>
                        <a onClick={()=>{
                            if(name.length===0) return;
                            updateCategory(teamData.team, categoryData, {
                                commentRoles: commentRolesNew,
                                createIdeaRoles: createRolesNew,
                                description,
                                name,
                                viewRoles: viewRolesNew,
                                voteIdeaRoles: voteRolesNew
                            }).then((category)=>{
                                if(category)
                                    document.location = "/team/"+teamData.team.key+"/category/"+category.key+"/"
                            })
                        }} className="create__btn-create btn btn--type-02">Update Category</a>
                    </div>:""}
                </div>
            </div>
        </PageTemplate>
    );
}

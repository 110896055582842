import {useLoaderData, useNavigate} from "react-router-dom";
import TeamDE from "../services/API/TeamDE";
import {useGlobalDispatch, useGlobalSelector} from "../stores/global.hooks";
import {useEffect} from "react";
import {setSubtitle, setTitle} from "../stores/global.reducer";
import {Idea} from "../components/Idea";
import {PageTemplate} from "./page";
import {getIdeaData} from "../stores/idea.reducer";


export const IdeaPage = () => {
    const navigate = useNavigate();
    const dispatch = useGlobalDispatch();
    useEffect(()=>{
        dispatch({
            type: setTitle.type,
            payload:teamData.team.data.name
        });
        dispatch({
            type: setSubtitle.type,
            payload: categoryData.data.description
        });

    }, []);
    const data = useLoaderData() as TeamDE;
    const teamInfo = useGlobalSelector(state=>state.teams);
    const teamData = teamInfo.teams[teamInfo.selected];
    const categoryInfo = useGlobalSelector(state=>state.categories);
    const categoryData = categoryInfo.categories[categoryInfo.selected];
    const ideaInfo = useGlobalSelector(state=>state.ideas);
    const ideaData = ideaInfo.ideaRef[ideaInfo.currentTree.idea.ideaKey];

    if(!categoryData) return <></>
    if(!ideaData){
        getIdeaData(teamData.team.key, categoryData.key, ideaInfo.currentTree.idea.ideaKey, dispatch).then();
        return <></>
    }
    return (
        <PageTemplate key={"page"} valKey={ideaData.idea.key}>
            <div className="container">
                <div className="nav">
                    <div className="nav__thread" style={{justifyContent: "flex-start", width:"100%"}}>
                        <a style={{fontSize: 13, paddingLeft: 10, paddingRight: 10}} onClick={()=>navigate("/team/"+teamData.team.key+"/")} className="">{teamData.team.data.name}</a> /
                        <a style={{fontSize: 13, paddingLeft: 10, paddingRight: 10}} onClick={()=>navigate("/team/"+teamData.team.key+"/category/"+categoryData.key+"/")} className="">{categoryData.data.name}</a> /
                        <span style={{fontSize: 13, fontStyle:"italic", paddingLeft: 10}}>{ideaData.idea.data.title}</span>
                    </div>
                </div>
                <div className="topics">
                    <div className="topics__heading">
                        <h2 className="topics__heading-title">{ideaData.idea.data.title}</h2>
                        <div className="topics__heading-info">
                            <i>{ideaData.idea.data.summary}</i>
                        </div>
                    </div>
                    <div className="topics__body">
                        <div className="topics__content">
                            <Idea key={ideaInfo.currentTree.idea.ideaKey} ideaTree={ideaInfo.currentTree} depth={0}/>
                        </div>
                    </div>
                </div>
            </div>
        </PageTemplate>
    );
}

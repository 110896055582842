import {useGlobalSelector} from "../stores/global.hooks";
import {updateCategory} from "../common/permissons/category";
import {useNavigate} from "react-router-dom";
import {permissionHandle} from "../stores/team.reducer";

export const CategoryListItem = (props: {categoryKey: string}) => {
    const navigate = useNavigate();
    const categories = useGlobalSelector(state=>state.categories);
    const teamInfo = useGlobalSelector(state=>state.teams);
    const teamData = teamInfo.teams[teamInfo.selected];
    const category = categories.categories[props.categoryKey];
    if(!category) return <></>;
    return (
        <div key={category.key} className="posts__item" style={{width:"100%", borderBottom:"1px solid #f0f0f0"}}>
            <div className="posts__section-left"style={{width:"100%", display:"flex", flexDirection:"row", paddingLeft:20, paddingRight:20, fontSize:14}}>
                <div className="posts__name" style={{flexGrow:"1"}}>
                    <div className="posts__content">
                        <a onClick={()=>navigate("/team/"+teamInfo.teams[teamInfo.selected].team.key+"/category/"+props.categoryKey+"/")}>
                            <h3>{category.data.name}</h3>
                        </a>
                    </div>
                    <div className="posts__content" style={{marginLeft:10}}><p>{category.data.description}</p></div>
                </div>
                {permissionHandle(teamInfo).can(updateCategory, null)?<div style={{width: 50, fontSize:20}}><a onClick={()=>navigate("/team/"+teamData.team.key+"/category/"+category.key+"/edit")}><i className={"icon-list_alt"}/></a></div>:""}
            </div>
        </div>
    );
}

import {useGlobalDispatch, useGlobalSelector} from "../stores/global.hooks";
import {useEffect} from "react";
import {setSubtitle, setTitle} from "../stores/global.reducer";
import {IdeaListItem} from "../components/IdeaListItem";
import {updateCategory} from "../common/permissons/category";
import {createIdea} from "../common/permissons/idea";
import {PageTemplate} from "./page";
import {useNavigate} from "react-router-dom";
import {permissionHandle} from "../stores/team.reducer";

export const CategoryPage = (props: {type: string}) => {
    const navigate = useNavigate();
    const dispatch = useGlobalDispatch();
    useEffect(()=>{
        dispatch({
            type: setTitle.type,
            payload:teamData.team.data.name
        });
        dispatch({
            type: setSubtitle.type,
            payload: categoryData?.data?.description
        });
    }, []);
    const teamInfo = useGlobalSelector(state=>state.teams);
    const teamData = teamInfo.teams[teamInfo.selected];
    const categoryInfo = useGlobalSelector(state=>state.categories);
    const categoryData = categoryInfo.categories[categoryInfo.selected];
    const ideas = useGlobalSelector(state=>state.ideas);

    if(!categoryData) return <></>
    return (
        <PageTemplate key={"page"} valKey={categoryData.key}>
            <div className="container">
                <div className="page__content__header">
                    <div className="page__content__header__item" style={{marginTop: 20, padding:20, display:"block"}}>
                        <h5>{categoryData.data.name}</h5>
                    </div>
                </div>
                <div className="nav">
                    <div className="nav__categories js-dropdown" style={{display:"flex", flexDirection: "row", justifyContent: "flex-end", width: "100%"}}>
                        <div className="nav__thread" style={{justifyContent: "flex-start", width:"100%"}}>
                            <a style={{fontSize: 13, paddingLeft: 10, paddingRight: 10}} onClick={()=>navigate("/team/"+teamData.team.key+"/")} className="">{teamData.team.data.name}</a> /
                            <span style={{fontSize: 13, fontStyle:"italic", paddingLeft: 10}}>{categoryData.data.name}</span>
                        </div>
                        <div className="nav__select" style={{margin: "0px", display:"flex", flexDirection:"row"}}>
                            {permissionHandle(teamInfo).can(updateCategory, null)?<a onClick={()=>navigate("/team/"+teamData.team.key+"/category/"+categoryData.key+"/edit")} className={"btn"}><i style={{fontSize: 20}} className={"icon-settings"}/> Settings</a>:""}
                            {permissionHandle(teamInfo).can(createIdea, {category: categoryData})?<a onClick={()=>navigate("/team/"+teamData.team.key+"/category/"+categoryData.key+"/create/idea")} className={"btn btn--type-02"}><i style={{fontSize: 20}} className={"icon-post_add"}/> Create</a>:""}
                        </div>
                    </div>
                    <div className="nav__menu js-dropdown">
                        <ul>
                            <li className={props.type==="published"?"active":""}><a onClick={()=>navigate("/team/"+teamData.team.key+"/category/"+categoryData.key+"/")}>Published</a></li>
                            <li className={props.type==="unpublished"?"active":""}><a onClick={()=>navigate("/team/"+teamData.team.key+"/category/"+categoryData.key+"/unpublished/")}>Unpublished</a></li>
                            <li className={props.type==="hidden"?"active":""}><a onClick={()=>navigate("/team/"+teamData.team.key+"/category/"+categoryData.key+"/hidden/")}>Hidden</a></li>
                        </ul>
                    </div>
                </div>
                <div className="posts">
                    <div className="posts__head" style={{width:"100%", flexDirection:"row", display:"flex", paddingLeft:20, paddingRight:20}}>
                        <div style={{display:"inline-block", width:"50px"}}></div>
                        <div style={{display:"inline-block", flexGrow:"1"}}>Name</div>
                        <div style={{width:"100px", display:"inline-block",textAlign:"center"}}><i style={{color:"black"}} className={"icon-arrow_upward"}/></div>
                        <div style={{width:"100px", display:"inline-block",textAlign:"center"}}><i style={{color:"black"}} className={"icon-arrow_downward"}/></div>
                        <div style={{width:"100px", display:"inline-block",textAlign:"center"}}>Members</div>
                        <div style={{width:"100px", display:"inline-block",textAlign:"center"}}>Voted</div>
                        <div style={{width:"80px", display:"inline-block", textAlign:"right"}}>Created</div>
                        <div style={{width:"80px", display:"inline-block", textAlign:"right"}}>Modified</div>
                    </div>
                    <div className="posts__body">
                        {Object
                            .entries(ideas.ideas)
                            .map(([key, data])=>({data, element:<IdeaListItem type={props.type} ideaKey={key} key={key}/>}))
                            .sort(a=>new Date(a.data.idea.idea.modified).getTime())
                            .map(c=>c.element)
                        }
                    </div>
                </div>
            </div>
        </PageTemplate>
    );
}

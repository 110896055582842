import {useGlobalDispatch, useGlobalSelector} from "../stores/global.hooks";
import {generateMarkdown} from "../pages/CreateIdea";
import TeamDE from "../services/API/TeamDE";
import CategoryDE from "../services/API/CategoryDE";
import IdeaDE from "../services/API/IdeaDE";
import {decide, postProcess, postRecommended, publishIdea, vote} from "../services/APIRequests";
import IdeaListItem from "../services/API/IdeaListItem";
import {getIdeaData, IdeaMap, IdeaMapEntry} from "../stores/idea.reducer";
import {useEffect, useState} from "react";
import {useStompClient, useSubscription} from "react-stomp-hooks";
import {createIdea, processIdea, recommendedIdea, updateIdea, voteIdea} from "../common/permissons/idea";
import {permissions} from "../common/permissons";
import {useNavigate} from "react-router-dom";
import {permissionHandle} from "../stores/team.reducer";
import {IdeaIdTree} from "../services/API/IdeaTree";


const publish = async (team: TeamDE, category: CategoryDE, idea: IdeaDE) => {
    await publishIdea(team.key, category.key, idea.key);
}
const process = async (team: TeamDE, idea: IdeaDE) => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm("Ar you sure you want to process this idea?\nDoing so will cost team credits.")){
        await postProcess(team, idea);
    }
    //await publishIdea(team.key, category.key, idea.key);
}
const recommendations = async (team: TeamDE, idea: IdeaDE) => {
    // eslint-disable-next-line no-restricted-globals
    if(confirm("Are you sure you want to generate recommendations?\nDoing so will cost team credits.")){
        await postRecommended(team, idea);
    }
    //await publishIdea(team.key, category.key, idea.key);
}
const voteUp = async (team: TeamDE, idea: IdeaDE) => {
    await vote(team.key, idea.key, true);
}
const voteDown = async (team: TeamDE, idea: IdeaDE) => {
    await vote(team.key, idea.key, false);
}

const decideYes = async (team: TeamDE, idea: IdeaDE) => {
    await decide(team.key, idea.key, true);
}
const decideNo = async (team: TeamDE, idea: IdeaDE) => {
    await decide(team.key, idea.key, false);
}
interface SortableIdeaList {
    entry: IdeaIdTree,
    element: JSX.Element
}
const generateChildrenIdeas = (children: IdeaIdTree[], depth: number) =>  {
    if(!children || Object.values(children).length==0) return "";
    return Object
        .entries(children)
        .map(([key, entry])=>({ entry, element:<Idea key={entry.idea.ideaKey} ideaTree={entry} depth={depth+1}/>}) as SortableIdeaList)
        // .sort((a:SortableIdeaList, b:SortableIdeaList)=>{
        //     if(b.entry.idea.idea.data.decided != a.entry.idea.meta..idea.data.decided) {
        //         return (a.entry.idea.idea.data.decided?1:0) - (b.entry.idea.idea.data.decided?1:0);
        //     }else if(b.entry.idea.idea.data.decided){
        //         return (a.entry.idea.idea.data.accepted?0:1) - (b.entry.idea.idea.data.accepted?0:1)
        //     }
        //     return new Date(b.entry.idea.idea.created).getTime()-new Date(a.entry.idea.idea.created).getTime();
        // })
        .map(a=>a.element);
}

const generateStyles = (idea: IdeaListItem, depth: number, expanded:boolean) => {
    const styles ={background: '#ffffff', borderRadius:8, border:"1px solid #f0f0f0"} as  {[key:string]: any};

    if(idea.idea.data.decided){
        styles['background'] = "#cecece";
        styles['overflow']="hidden";
        if(!expanded) styles['height']="100px";
    }
    if(idea.idea.data.accepted){
        styles['background'] = "#f7fff6";
    }
    if(idea.idea.data.rejected){
        styles['background'] = "#ffeeee";
    }
    if(idea.idea.data.actionRequired){
        styles['background'] = "#ffefd3";
        styles['border'] = "2px dashed #fffc6d";

    }
    if(idea.idea.data.type=="temp"){
        styles['background'] = "#f5f5f5";
        styles['border'] = "1px dashed #757575";
    }
    styles['margin-left'] = 40*depth;
    return styles;
}
export const Idea = (props: { ideaKey?: string, ideaDEObject?: IdeaListItem, ideaTree?: IdeaIdTree, depth: number }) => {
    const navigate = useNavigate();
    const globalDispatch = useGlobalDispatch();
    const [expanded, setExpanded] = useState(false);
    const [retrieveIdea, setRetrieveIdea] = useState(false);
    const teamInfo = useGlobalSelector(state => state.teams);
    const teamData = teamInfo.teams[teamInfo.selected];
    const categoryInfo = useGlobalSelector(state => state.categories);
    const categoryData = categoryInfo.categories[categoryInfo.selected];
    const ideas = useGlobalSelector(state => state.ideas);
    let ideaData: IdeaListItem| undefined;
    if(props.ideaKey) ideaData = ideas.ideaRef[props.ideaKey];
    if(props.ideaDEObject) ideaData = props.ideaDEObject;
    if(props.ideaTree) ideaData = ideas.ideaRef[props.ideaTree.idea.ideaKey];
    let ideaKey: string | undefined;
    if(props.ideaKey) ideaKey = props.ideaKey
    if(props.ideaDEObject?.idea?.key) ideaKey = props.ideaDEObject?.idea?.key;
    if(props.ideaTree?.idea?.ideaKey) ideaKey = props.ideaTree?.idea?.ideaKey;
    const profile = useGlobalSelector(state=>state.profile);
    const children = props.ideaTree?.children;
    const stompClient = useStompClient();
    const windowId = useGlobalSelector(state=>state.globals.id);
    useEffect(()=>{
        stompClient?.publish({
            destination: "/app/updates/idea",
            body: JSON.stringify({ key: ideaKey, windowId})
        })
    },[]);

    useEffect(()=>{
        if(!ideaData){
            setRetrieveIdea(true);
            if(ideaKey && !retrieveIdea) getIdeaData(teamData.team.key, categoryData.key, ideaKey, globalDispatch).then();
        }
    },[]);

    if(!ideaData){
        return <>LOADING</>;
    }

    const styles = generateStyles(ideaData, props.depth, expanded);


    return (
        <>
            <div style={{...styles, display:"flex", flexDirection: "row"}}>
                {ideaData.idea.data.decided?<>
                    <div onClick={()=>setExpanded(!expanded)} style={{...(ideaData.idea.data.accepted?{borderRight:"1px solid #f0f0f0"}:{borderRight:"1px solid #fff"}), width:40, cursor:"pointer", color:"#a0a0a0", display:"flex", justifyContent:"center", alignItems:"center"}}>
                        <i className={"fa "+(expanded?"fa-long-arrow-up":"fa-expand")}/>
                    </div>
                </>:""}
                <div className="topic" style={{background:'none', border:'none', flexGrow: "1"}} id={props.ideaKey}>
                    <div className="topic__head" style={{padding:0}}>
                        <div className="topic__avatar">
                            <a onClick={()=>navigate("/user/profile/" + ideaData.author.key)} className="avatar"><img
                                src={ideaData.author.data.avatar.url} alt="avatar"/></a>
                        </div>
                        <div className="topic__caption">
                            <div className="topic__name">
                                <a onClick={()=>navigate("/user/profile/" + ideaData.author.key)}>{ideaData.author.data.name}</a>
                            </div>
                            <div className="topic__date" style={{fontSize: 15, color:"lightgrey"}}>
                                {new Date(ideaData.idea.created).toISOString()}
                            </div>
                        </div>
                    </div>
                    <div className="topic__head">
                        <div className="topic__caption">
                            <div className="topic__date">Version: {ideaData.idea.version}</div>
                        </div>
                    </div>
                    <div className="topic__content">
                        <div className="topic__text" style={{...(ideaData.idea.data.decided?{border:"1px solid #f0f0f0", padding:10}:{}),background:"#fff", borderRadius:8, display:"block"}}>
                            {generateMarkdown(ideaData.idea.data.body)}
                            {ideaData.idea?.data?.extra?
                                [
                                    <br/>,
                                    <i>{ideaData.idea?.data?.extra}</i>
                                ]:''
                            }
                        </div>
                        {ideaData.idea.data.type=="idea"?
                            <div className="topic__footer">
                                <div className="topic__footer-likes">
                                    {!ideaData.idea.data.decided && permissionHandle(teamInfo).can(voteIdea, {category: categoryData}) && ideaData.idea.data.published && ideaData.meta.subIdeas - ideaData.meta.decidedIdeas == 0 ? <>
                                        <div>
                                            <a onClick={() => voteUp(teamData.team, ideaData.idea)}><i style={{fontSize: 20, color:"green"}} className="icon-arrow_upward"></i></a>
                                            <span>{ideaData.voteResults.upVote}</span>
                                        </div>
                                        <div>
                                            <a onClick={() => voteDown(teamData.team, ideaData.idea)}><i style={{fontSize: 20, color:"red"}} className="icon-arrow_downward"></i></a>
                                            <span>{ideaData.voteResults.downVote}</span>
                                        </div>
                                    </> : ""}
                                </div>
                                <div className="topic__footer-share">
                                    <div>
                                        <a href={"#"+props.ideaKey}><i style={{fontSize: 20, color:"black"}} className="icon-link"></i></a>
                                    </div>
                                </div>
                                {!ideaData.idea.data.published && permissionHandle(teamInfo).can(permissions.publishIdea, {profile: profile.profile, author: ideaData.author}) && !ideaData.idea.data.decided ?
                                    <a title={"Publish Idea"} onClick={() => publish(teamData.team, categoryData, ideaData.idea)} style={{marginLeft: 20}}>
                                        <i style={{fontSize: 20, color:"blue"}} className="icon-upload_file"></i>
                                    </a> : ""}
                                {ideaData.idea.data.published && permissionHandle(teamInfo).can(createIdea, {category: categoryData}) && !ideaData.idea.data.decided ?
                                    <a title={"Create Idea"} onClick={()=>navigate(`/team/${teamData.team.key}/category/${categoryData.key}/idea/${ideaData.idea.key}/create/idea`)} style={{marginLeft: 20}}>
                                        <i style={{fontSize: 20, color:"darkolivegreen"}} className="icon-post_add"></i>
                                    </a>
                                    :""}
                                {ideaData.idea.data.published && permissionHandle(teamInfo).can(processIdea, {category: categoryData}) && !ideaData.idea.data.decided ?
                                    <a title={"Process Idea"} onClick={() => process(teamData.team, ideaData.idea)} style={{marginLeft: 20}}>
                                        <i style={{fontSize: 20, color:"rosybrown"}} className="icon-repeat"></i>
                                    </a>
                                    :""}
                                {ideaData.idea.data.published && permissionHandle(teamInfo).can(recommendedIdea, {category: categoryData}) && !ideaData.idea.data.decided ?
                                    <a title={"Generate Recommendation Ideas"} onClick={() => recommendations(teamData.team, ideaData.idea)} style={{marginLeft: 20}}>
                                        <i style={{fontSize: 20, color:"blueviolet"}} className="icon-dynamic_feed"></i>
                                    </a>
                                    :""}
                            </div>
                        :""}
                        {ideaData.idea.data.type=="temp"?
                            <div className="topic__footer">
                                <div className="topic__footer-likes">
                                    {!ideaData.idea.data.decided && permissionHandle(teamInfo).can(voteIdea, {category: categoryData}) && ideaData.idea.data.published && ideaData.meta.subIdeas - ideaData.meta.decidedIdeas == 0 ? <>
                                        <div>
                                            <a onClick={() => decideYes(teamData.team, ideaData.idea)}><i style={{fontSize: 20, color:"green"}} className="icon-check"></i></a>
                                        </div>
                                        <div>
                                            <a onClick={() => decideNo(teamData.team, ideaData.idea)}><i style={{fontSize: 20, color:"red"}} className="icon-delete"></i></a>
                                        </div>
                                    </> : ""}
                                </div>
                            </div>
                        :""}
                    </div>
                </div>
            </div>
            <div>
                {ideaData.pending?.length?<><h3 style={{fontSize:"25px"}}>Pending Merger</h3><hr/></>:""}
                {ideaData.pending.map(idea=>{
                    return <Idea ideaDEObject={idea} depth={props.depth}></Idea>
                })}
            </div>
            {(children)?generateChildrenIdeas(children, props.depth):<></>}
        </>
    );
}

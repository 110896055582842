import {useLoaderData, useNavigate} from "react-router-dom";
import UserDE from "../services/API/UserDE";
import {useGlobalSelector} from "../stores/global.hooks";
import TeamListItem from "../services/API/TeamListItem";
import Connection from "../services/API/Connection";
import {createInvite} from "../common/permissons/invite";
import {PageTemplate} from "./page";
import {permissionHandle} from "../stores/team.reducer";

export const InviteAcceptedList = () => {
    const navigate = useNavigate();
    const data = useLoaderData() as {user: UserDE, inviteAccept: Connection}[];
    const teamInfo = useGlobalSelector(state=>state.teams);
    const teamData = teamInfo.teams[teamInfo.selected];
    const inviteAcceptedItem = (data: {user: UserDE, inviteAccept: Connection}, teamData: TeamListItem)=>{
        return (
            <>
                <div className="posts__item" style={{width:"100%", borderBottom:"1px solid #f0f0f0"}}>
                    <div className="posts__section-left"style={{width:"100%", display:"flex", flexDirection:"row", paddingLeft:20, paddingRight:20, fontSize:14}}>
                        <div style={{width:150}}>
                            <img style={{width:75}} src={data.user.data.avatar.url}/>
                        </div>
                        <div style={{flexGrow:"1"}}>
                            <a onClick={()=>navigate("/user/profile/"+data.user.key)}>{data.user.data.name}</a>
                        </div>
                        <div style={{width:400}}>
                            {data.inviteAccept.date}
                        </div>
                    </div>
                </div>
            </>

        )
    }
    return (
        <PageTemplate key={"page"} valKey={`inviteAcceptedList`}>
            <div className="container">
                <div className="nav">
                    <div className="nav__categories js-dropdown" style={{display:"flex", flexDirection: "row", justifyContent: "flex-end", width: "100%"}}>
                        <div className="nav__thread" style={{justifyContent: "flex-start", width:"100%"}}>
                            <a style={{fontSize: 13, paddingLeft: 10, paddingRight: 10}} onClick={()=>navigate("/team/"+teamData.team.key+"/")} className="">{teamData.team.data.name}</a> /
                            <span style={{fontSize: 13, fontStyle:"italic", paddingLeft: 10}}>Accepted Invite</span>
                        </div>
                        <div className="nav__select" style={{margin: "0px", display:"flex", flexDirection:"row"}}>
                            {permissionHandle(teamInfo).can(createInvite, null)?<a onClick={()=>navigate("/team/"+teamData.team.key+"/invite/create")} className={"btn"}><i className={"fa fa-gear"}/> Create Invite</a>:""}
                        </div>
                    </div>
                </div>
                <div className="posts">
                    <div className="posts__head" style={{width:"100%", flexDirection:"row", display:"flex", paddingLeft:20, paddingRight:20}}>
                        <div style={{width:150}}></div>
                        <div style={{display:"inline-block", flexGrow:"1"}}>Name</div>
                        <div style={{width:400, display:"inline-block"}}>Invite Accepted</div>
                    </div>
                    <div className="posts__body">
                        {data.map(d=>inviteAcceptedItem(d, teamData))}
                    </div>
                </div>
            </div>
        </PageTemplate>
    );
}

import {useEffect, useState} from "react";
import {useGlobalDispatch, useGlobalSelector} from "../stores/global.hooks";
import {setSubtitle, setTitle} from "../stores/global.reducer";
import Slider from '@uiw/react-slider';
import {updateTeam, updateTeamSettings} from "../services/APIRequests";
import Checkbox from "@uiw/react-checkbox";
import {PageTemplate} from "./page";
import {useNavigate} from "react-router-dom";

export const SettingTeamPage = () => {
    const navigate = useNavigate();
    const dispatch = useGlobalDispatch();
    useEffect(()=>{
        dispatch({
            type: setTitle.type,
            payload: "Edit Team Settings"
        });
        dispatch({
            type: setSubtitle.type,
            payload: ""
        });
    }, []);
    const teamInfo = useGlobalSelector(state=>state.teams);
    const teamData = teamInfo.teams[teamInfo.selected];
    const [canInvite, setCanInvite] = useState( teamData.team.data.teamSettings.canInvite?? [] as string[]);
    const [canPercentAccept, setCanPercentAccept] = useState( teamData.team.data.teamSettings.percentAccept*100);
    const [canPercentReject, setCanPercentReject] = useState( teamData.team.data.teamSettings.percentReject*100);

    return (
        <PageTemplate key={"page"} valKey={`settingTeam-${teamData.team.key}`}>
            <div className="container">
                <div className="nav">
                    <div className="nav__thread" style={{justifyContent: "flex-start", width:"100%"}}>
                        <a style={{fontSize: 13, paddingLeft: 10, paddingRight: 10}} onClick={()=>navigate("/team/"+teamData.team.key+"/")} className="">{teamData.team.data.name}</a> /
                        <span style={{fontSize: 13, fontStyle:"italic", paddingLeft: 10}}>Edit Team Settings</span>
                    </div>
                </div>
                <div className="create">
                    <div className="create__head">
                        <div className="create__title"><img src="/fonts/icons/main/Settings.svg" alt="New team"/>Edit Team Settings</div>
                        <span></span>
                    </div>
                    <div className="create__section create__textarea">
                        <label className="create__label" htmlFor="description">{canPercentAccept}% Percent To Accept Revisions</label>
                        <Slider
                            // progress={false}
                            progress="#dc3545"
                            min={1}
                            max={100}
                            value={canPercentAccept}
                            style={{ width: "80%", marginLeft:"10%", marginRight:"10%", marginTop: 30 }}
                            onChange={(value)=> (typeof(value) == "number")?setCanPercentAccept(value):""}
                        />
                    </div>
                    <div className="create__section create__textarea">
                        <label className="create__label" htmlFor="description">{canPercentReject}% Percent To Reject Revisions</label>
                        <Slider
                            // progress={false}
                            progress="#dc3545"
                            min={1}
                            max={100}
                            value={canPercentReject}
                            style={{ width: "80%", marginLeft:"10%", marginRight:"10%", marginTop: 30 }}
                            onChange={(value)=> (typeof(value) == "number")?setCanPercentReject(value):""}
                        />
                    </div>
                    <div className="create__section">
                        <label className="create__label">Can Invite</label>
                        {teamInfo.roles.map(roleAndPerm=><Checkbox onChange={(x)=>{
                            const name = roleAndPerm.role.data.name;
                            if(canInvite.includes(name)) setCanInvite(canInvite.filter(r=>r!==name))
                            else setCanInvite([...canInvite, name])
                        }} checked={canInvite.includes(roleAndPerm.role.data.name)}>{roleAndPerm.role.data.name}</Checkbox>)}
                    </div>

                    <div className="create__footer" style={{display: "block", textAlign: "right"}}>
                        <a onClick={()=>{
                            updateTeamSettings(teamData.team, {
                                ...teamData.team.data.teamSettings,
                                canInvite,
                                percentAccept: canPercentAccept/100,
                                percentReject: canPercentReject/100
                            }).then((team)=>{
                                if(team)
                                    document.location = "/team/"+team.key
                            })

                        }} className="create__btn-create btn btn--type-02">Save Settings</a>
                    </div>
                </div>
            </div>
        </PageTemplate>
    );
}

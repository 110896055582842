import {useGlobalDispatch, useGlobalSelector} from "../stores/global.hooks";
import {useLoaderData, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {setSubtitle, setTitle} from "../stores/global.reducer";
import InviteMeta from "../services/API/InviteMeta";
import TeamListItem from "../services/API/TeamListItem";
import {activateInvite, deactivateInvite} from "../services/APIRequests";
import InviteDE from "../services/API/InviteDE";
import {acceptedInvite, createInvite, updateInvite} from "../common/permissons/invite";
import {permissions} from "../common/permissons";
import {PageTemplate} from "./page";
import {permissionHandle} from "../stores/team.reducer";


export const InviteList = () => {
    const navigate = useNavigate();
    const dispatch = useGlobalDispatch();
    useEffect(()=>{
        dispatch({
            type: setTitle.type,
            payload: "Team Invites"
        });
        dispatch({
            type: setSubtitle.type,
            payload: ""
        });
    }, []);

    const data = useLoaderData() as InviteMeta[];
    const teamInfo = useGlobalSelector(state=>state.teams);
    const teamData = teamInfo.teams[teamInfo.selected];
    const [invites, setInvites] = useState(data);
    const [pending, setPending] = useState(false);


    const inviteItem = (data: InviteMeta, teamData: TeamListItem)=>{
        return (
            <>
                <div className="posts__item" style={{width:"100%", borderBottom:"1px solid #f0f0f0"}}>
                    <div className="posts__section-left"style={{width:"100%", display:"flex", flexDirection:"row", paddingLeft:20, paddingRight:20, fontSize:14}}>
                        <div style={{flexGrow:"1"}}>
                            {data.creator.data.name}
                        </div>
                        <div style={{width:250}}>
                            {data.invite.data.domain ?? "any"}
                        </div>
                        <div style={{width:250}}>
                            {data.invite.data.source ?? "any"}
                        </div>
                        <div style={{width:150}}>
                            {data.role.data.name}
                        </div>
                        <div style={{width:150}}>
                            {permissionHandle(teamInfo).can(acceptedInvite, null)?<a onClick={()=>navigate("/team/"+teamData.team.key+"/invite/"+data.invite.key+"/accepted")} style={{paddingRight:5}}>{data.invite.data.invitesUsed}</a>:data.invite.data.invitesUsed} / {data.invite.data.invites}
                        </div>
                        <div style={{width:100, fontSize:24}}>
                            <a style={{cursor:"pointer",marginRight:20}} onClick={()=>navigate("/invite/"+data.invite.key)}><i className={"icon-link"}/></a>
                            {permissionHandle(teamInfo).can(updateInvite, null)?<a onClick={()=>navigate("/team/"+teamData.team.key+"/invite/"+data.invite.key)} style={{marginRight:20}}><i className={"icon-settings_applications"}/></a>:""}
                            {data.invite.data.active?
                                <a title={"Deactivate Invite"} style={{cursor:"pointer", color: pending?"#f5f5f5":"#989898"}} onClick={async ()=>{
                                    if(permissionHandle(teamInfo).can(permissions.deactivateInvite, null) && !pending){
                                        setPending(true);
                                        const invite = await deactivateInvite(data.invite.key, teamData.team.key) as InviteDE;
                                        const copiedState = JSON.parse(JSON.stringify(invites)) as InviteMeta[];
                                        const inviteFound = copiedState.find(c=>c.invite.key === invite.key);
                                        if(inviteFound)inviteFound.invite=invite;
                                        setInvites(copiedState);
                                        setPending(false);
                                        //document.location.reload();
                                    }
                                }}><i className={"icon-flash_on"} style={{color:"lightgreen"}} /></a>:
                                <a title={"Activate Invite"} style={{cursor:"pointer"}} onClick={async ()=>{
                                    if(permissionHandle(teamInfo).can(permissions.activateInvite, null) && !pending){
                                        setPending(true);
                                        const invite = await activateInvite(data.invite.key, teamData.team.key) as InviteDE;
                                        const copiedState = JSON.parse(JSON.stringify(invites)) as InviteMeta[];
                                        const inviteFound = copiedState.find(c=>c.invite.key === invite.key);
                                        if(inviteFound)inviteFound.invite=invite;
                                        setInvites(copiedState);
                                        setPending(false);
                                        //document.location.reload();
                                    }
                                }}><i className={"icon-flash_off"} style={{color:"darkred"}} /></a>
                            }
                        </div>
                    </div>
                </div>
            </>

        )
    }
    return (
        <PageTemplate key={"page"} valKey={`inviteList`}>
            <div className="container">
                <div className="nav">
                    <div className="nav__categories js-dropdown" style={{display:"flex", flexDirection: "row", justifyContent: "flex-end", width: "100%"}}>
                        <div className="nav__thread" style={{justifyContent: "flex-start", width:"100%"}}>
                            <a style={{fontSize: 13, paddingLeft: 10, paddingRight: 10}} onClick={()=>navigate("/team/"+teamData.team.key+"/")} className="">{teamData.team.data.name}</a> /
                            <span style={{fontSize: 13, fontStyle:"italic", paddingLeft: 10}}>Invite List</span>
                        </div>
                        <div className="nav__select" style={{margin: "0px", display:"flex", flexDirection:"row"}}>
                            {permissionHandle(teamInfo).can(createInvite, null)?<a onClick={()=>navigate("/team/"+teamData.team.key+"/invite/create")} className={"btn"}><i className={"icon-settings"}/>Create</a>:""}
                        </div>
                    </div>
                </div>
                <div className="posts">
                    <div className="posts__head" style={{width:"100%", flexDirection:"row", display:"flex", paddingLeft:20, paddingRight:20}}>
                        <div style={{display:"inline-block", flexGrow:"1"}}>Creator</div>
                        <div style={{width:250, display:"inline-block"}}>Domain</div>
                        <div style={{width:250, display:"inline-block"}}>Source</div>
                        <div style={{width:150, display:"inline-block"}}>InviteFor</div>
                        <div style={{width:150, display:"inline-block"}}>Invites</div>
                        <div style={{width:100, display:"inline-block"}}></div>
                    </div>
                    <div className="posts__body">
                        {invites
                            .map(d=>({e:inviteItem(d, teamData), created: new Date(d.invite.created).getTime()}))
                            .sort((a,b)=>a.created-b.created)
                            .map(d=>d.e)
                        }
                    </div>
                </div>
            </div>
        </PageTemplate>
    );
}

import {useGlobalDispatch, useGlobalSelector} from "../stores/global.hooks";
import {CategoryListItem} from "../components/CategoryListItem";
import {setSubtitle, setTitle} from "../stores/global.reducer";
import {useEffect} from "react";
import {generateMarkdown} from "./CreateTeam";
import {permissions} from "../common/permissons";
import {PageTemplate} from "./page";
import {useNavigate} from "react-router-dom";
import {permissionHandle} from "../stores/team.reducer";

export const TeamPage = () => {
    const navigate = useNavigate();
    const dispatch = useGlobalDispatch();
    const categories = useGlobalSelector(state=>state.categories);
    const teamInfo = useGlobalSelector(state=>state.teams);
    const teamData = teamInfo.teams[teamInfo.selected];
    useEffect(()=>{
        dispatch({
            type: setTitle.type,
            payload: teamData.team.data.name
        });
        dispatch({
            type: setSubtitle.type,
            payload: "Category Listing"
        });
    }, []);
    return (
        <PageTemplate key={"page"} valKey={`team-${teamData.team.key}`}>
            <div className="container">
                <div className="page__content__header">
                    <div className="page__content__header__item" style={{marginTop: 20, padding:20, display:"block"}}>
                        {generateMarkdown(teamData.team.data.description)}
                    </div>
                </div>
                <div className="nav">
                    <div className="nav__categories js-dropdown" style={{display:"flex", flexDirection: "row", justifyContent: "flex-end", width: "100%"}}>
                        <div className="nav__select" style={{flexGrow:"1"}}>

                        </div>
                        <div className="nav__select" style={{margin: "0px", display:"flex", flexDirection:"row"}}>
                            {permissionHandle(teamInfo).can(permissions.updateCategory, null)?<a onClick={()=>navigate("/team/"+teamData.team.key+"/edit")} className={"btn"}><i className={"icon-create"}/>Edit</a>:""}
                            {permissionHandle(teamInfo).can(permissions.changeTeamSettings, null)?<a className={"btn"} onClick={()=>navigate("/team/"+teamData.team.key+"/settings")}><i className={"icon-settings"}/> Settings</a>:""}
                            {permissionHandle(teamInfo).can(permissions.updateRole, null)?<a className={"btn"} onClick={()=>navigate("/team/"+teamData.team.key+"/roles")}><i className={"icon-groups"}/> Roles</a>:""}
                            {permissionHandle(teamInfo).can(permissions.listInvite, null)?<a className={"btn"} onClick={()=>navigate("/team/"+teamData.team.key+"/invites")}><i className={"icon-mail_outline"}/> Invites</a>:""}
                            {permissionHandle(teamInfo).can(permissions.createCategory, null)?<a onClick={()=>navigate("/team/"+teamData.team.key+"/create/category")} className={"btn btn--type-02"}><i className={"icon-add_circle"}/>Create Category</a>:""}
                        </div>
                    </div>
                </div>
                <div className="posts">
                    <div className="posts__head">
                        <div className="posts__name">Categories</div>
                    </div>
                    <div className="posts__body">
                        {Object
                            .entries(categories.categories)
                            .map(([key, data])=>({element:<CategoryListItem categoryKey={key} key={key}/>, data}))
                            .sort((a,b)=>a.data.data.name.localeCompare(b.data.data.name))
                            .map(c=>c.element)
                        }
                    </div>
                </div>
            </div>
        </PageTemplate>
    );
}

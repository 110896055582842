import {ideaPerms} from "./idea";
import {rolePerms} from "./roles";
import {categoryPerms} from "./category";
import {Node, PermissionTree} from "../../services/PermissionTree";
import {GetPermissionContextType, PermissionType} from "./type";
import {invitePerms} from "./invite";
import {teamPerms} from "./team";

export const permissions = {
    ...ideaPerms,
    ...rolePerms,
    ...categoryPerms,
    ...invitePerms,
    ...teamPerms
};

export class PermissionHandle {
    permTree: Node;
    roleNames: string[] = [];


    constructor(permTree: Node, roleNames: string[]) {
        this.permTree = permTree;
        this.roleNames = roleNames;
    }

    can<P extends PermissionType<any>, Z extends GetPermissionContextType<P["check"]>>(permission: P, context: Z): boolean {
        return permission.check(context, {tree: this.permTree, roles: this.roleNames});
    }

}

export const atleastOneInList = (a: string[], b: string[]): boolean => {
    return a.filter(i => b.includes(i)).length > 0;
}
import React, {useEffect} from 'react';
import './App.css';
import {LoginPage} from "./pages/LoginPage";
import {populateProfileRequest} from "./stores/profile.reducer";
import {useGlobalDispatch, useGlobalSelector} from "./stores/global.hooks";
import {Loading} from "./components/Loading";
import {useSubscription} from "react-stomp-hooks";
import {populateTreeIdea, updateIdea} from "./stores/idea.reducer";
import IdeaListItem from "./services/API/IdeaListItem";
import {IndexRoutes} from "./routes";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {updateTeamData} from "./stores/team.reducer";
import {updateCategoryData} from "./stores/category.reducer";
import CategoryDE from "./services/API/CategoryDE";
import TeamDE from "./services/API/TeamDE";
import {IdeaIdTree} from "./services/API/IdeaTree";

function App() {
  const globalDispatch = useGlobalDispatch();
  const sessionProfile = useGlobalSelector((state) => state.profile);
  const windowId = useGlobalSelector(state=>state.globals.id);
  useSubscription("/user/stream/subscribe/idea", (message) => console.log(message.body));
  useSubscription(`/user/stream/${windowId}/updates/idea`, (message) => {
    console.log(message.body)
    globalDispatch({
      type: updateIdea.type,
      payload: JSON.parse(message.body) as IdeaListItem,
    });
  });
  useSubscription(`/user/stream/${windowId}/updates/idea/tree`, (message) => {
    console.log(message.body)
    globalDispatch({
      type: populateTreeIdea.type,
      payload: {ideaTreeResponse:JSON.parse(message.body) as IdeaIdTree},
    });
  });
  useSubscription(`/user/stream/${windowId}/updates/team`, (message) => {
    console.log(message.body)
    globalDispatch({
      type: updateTeamData.type,
      payload: JSON.parse(message.body) as TeamDE,
    });
  });
  useSubscription(`/user/stream/${windowId}/updates/category`, (message) => {
    console.log(message.body)
    globalDispatch({
      type: updateCategoryData.type,
      payload: JSON.parse(message.body) as CategoryDE,
    });
  });
  useEffect(()=>{
    populateProfileRequest(globalDispatch);
  }, []);

  const redirectTo = localStorage.getItem("redirectTo");

  if(!sessionProfile.checked){
    return <Loading/>
  }

  if(sessionProfile.isProfile) {
    if(redirectTo){
      localStorage.removeItem("redirectTo");
      document.location.href = redirectTo;
      return <></>;
    }else{
      const router = createBrowserRouter(IndexRoutes(globalDispatch, sessionProfile));
      return <RouterProvider router={router} fallbackElement={""}/>;
    }
  }else {
    if(document.location.href !== "https://brainstorm.im/login" && document.location.href !== "https://brainstorm.im/"){
      localStorage.setItem("redirectTo", document.location.href);
    }
    return (
        <LoginPage key={"loginPage"}/>
    );
  }
}

export default App;

import {useGlobalSelector} from "../../stores/global.hooks";
import {useNavigate} from "react-router-dom";
import {hostName} from "../../services/APIRequests";

export const Header = () => {
    const navigate = useNavigate();
    const profile = useGlobalSelector(state => state.profile);
    const teamInfo = useGlobalSelector(state=> state.teams);
    const globals = useGlobalSelector(state=> state.globals);
    return (
        <div className="header js-header js-dropdown">
            <div className="container" style={{width: window.innerWidth-80}}>
                <div className="header__logo">
                    <div className="header__logo-btn">
                        <a onClick={()=>navigate("/")}><img style={{marginRight:10}} src={"/images/logo.png"}/> Brainstorm</a>
                    </div>
                </div>
                <div className="header__title">
                    <h5>{globals.title}</h5>
                    <h6>{globals.subtitle}</h6>
                </div>
                <div className="header__user">
                    <div className="header__user-btn" data-dropdown-btn="user">
                        <img src={profile.profile.data.avatar.url} alt="avatar"/>
                        {profile.profile.data.name}<i style={{fontSize: 22}} className="icon-keyboard_arrow_down"></i>
                    </div>

                </div>
            </div>
            <nav className="dropdown dropdown--design-01" data-dropdown-list="user">
                <div>
                    <div className="dropdown__icons">
                        <a onClick={()=>navigate("/saved-ideas")}><i className="icon-bookmark"></i></a>
                        <a onClick={()=>navigate("/user/edit")}><i className="icon-settings"></i></a>
                        <a href={`https://${hostName}/logout`}><i className="icon-logout"></i></a>
                    </div>
                </div>
            </nav>
        </div>
    )
}

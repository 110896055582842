import {DefaultPermissionType, PermissionType} from "./type";
import {PermissionTree} from "../../services/PermissionTree";

export const listInvite = {
    perm: "team.permission.invite.list",
    depends: [],
    description: "Ability for a user to see a list of invites.",
    title: "List Invites",
    admin: "team.admin.invite.list",
    check(_, {tree, roles}): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const viewInvite = {
    perm: "team.permission.invite.view",
    admin: "team.admin.invite.view",
    depends: [listInvite],
    description: "Ability for a user to view invite edit page.",
    title: "Invite View",
    check(_, {tree, roles}): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const createInvite = {
    perm: "team.permission.invite.create",
    depends: [listInvite],
    description: "Ability for a user to create an invite.",
    title: "Create Invite",
    admin: "team.admin.invite.create",
    check(_, {tree, roles}): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const updateInvite = {
    perm: "team.permission.invite.update",
    depends: [listInvite],
    description: "Ability for a user to update an invite.",
    title: "Edit Invite",
    admin: "team.admin.invite.update",
    check(_, {tree, roles}): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const deactivateInvite = {
    perm: "team.permission.invite.deactivate",
    depends: [listInvite],
    description: "Ability for a user to deactivate an invite.",
    title: "Deactivate Invite",
    admin: "team.admin.invite.deactivate",
    check(_, {tree, roles}): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const activateInvite = {
    perm: "team.permission.invite.activate",
    depends: [listInvite],
    description: "Ability for a user to activate an invite.",
    title: "Activate Invite",
    admin: "team.admin.invite.activate",
    check(_, {tree, roles}): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const acceptedInvite = {
    perm: "team.permission.invite.accepted",
    depends: [listInvite],
    description: "Ability for a user to view the users that accepted a invite.",
    title: "Invite Accepted",
    admin: "team.admin.invite.accepted",
    check(_, {tree, roles}): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const invitePerms = {
    listInvite,
    viewInvite,
    createInvite,
    updateInvite,
    deactivateInvite,
    activateInvite,
    acceptedInvite
};
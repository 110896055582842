import {useLoaderData} from "react-router-dom";
import UserDE from "../services/API/UserDE";
import {PageTemplate} from "./page";

export const ProfilePage = () => {
    const user = useLoaderData() as UserDE;
    return (
        <PageTemplate key={"page"} valKey={`profilePage-${user.key}`}>
            <section style={{paddingTop:30}}>
                <div className={"container py-5"}>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="card mb-4">
                                <div className="card-body text-center">
                                    <img src={user.data.avatar.url}
                                         alt="avatar"
                                         className="rounded-circle img-fluid" style={{width: 150}}/>
                                        <h5 className="my-3">{user.data.name}</h5>
                                        <div className="d-flex justify-content-center mb-2">
                                            Invite to team here
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mb-4 mb-md-0">
                                        <div className="card-body">
                                            List of common teams here!
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </PageTemplate>
    );
}

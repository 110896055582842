import {createSlice, Dispatch, PayloadAction} from '@reduxjs/toolkit'
import {getProfile, getUser} from "../services/APIRequests";
import UserDE from "../services/API/UserDE";

export const profileSlice = createSlice({
    name: 'global',
    initialState: {
        checked: false,
        isProfile: false,
        profile: {} as UserDE,
        users: {} as {[key:string]: UserDE},
    },
    reducers: {
        updateProfileData: (state, action: PayloadAction<UserDE>) => {
            state.checked = true;
            if(action.payload) {
                if(!state.users[action.payload.key]){
                    state.users[action.payload.key] = action.payload;
                }else{
                    state.users[action.payload.key].data = action.payload.data;
                    state.users[action.payload.key].version = action.payload.version;
                    state.users[action.payload.key].modified = action.payload.modified;
                }
            }
        },
        populateProfile: (state, action: PayloadAction<UserDE>) => {
            state.checked = true;
            if(action.payload) {
                state.profile = action.payload;
                state.isProfile = true;
            }
        }
    },
});

export async function updateProfileDataRequest(userKey: string, dispatch: Dispatch) {
    const response = await getUser(userKey);
    return dispatch({
        type: updateProfileData.type,
        payload: response
    });
}

export async function populateProfileRequest(dispatch: Dispatch) {
    const response = await getProfile();
    return dispatch({
        type: populateProfile.type,
        payload: response
    });
}

export const {populateProfile, updateProfileData} = profileSlice.actions;

export default profileSlice.reducer;

import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {getProfileRoles, getRoles, getTeam, getTeams, updateTeam} from "../services/APIRequests";
import TeamListItem from "../services/API/TeamListItem";
import RoleDE from "../services/API/RoleDE";
import {Node, PermissionTree} from "../services/PermissionTree";
import {PermissionHandle} from "../common/permissons";
import TeamDE from "../services/API/TeamDE";

export interface RoleAndPermissionTree {
    role: RoleDE,
    permissionTreeRoot: Node
}

export const permissionHandle = (teamInfo: ITeamState): PermissionHandle => {
    return new PermissionHandle(teamInfo.allRoles, teamInfo.profileRoles);
}

interface ITeamState {
    checked: boolean;
    teams: { [key: string]: TeamListItem };
    teamList: string[];
    selected: string;
    roles: RoleAndPermissionTree[];
    allRoles: Node;
    profileRoles: string[];
}

export const teamsSlice = createSlice({
    name: 'global',
    initialState: {
        checked: false,
        teams: {} as { [key: string]: TeamListItem },
        teamList: [] as string[],
        selected: "",
        roles: [] as RoleAndPermissionTree[],
        allRoles: {} as Node,
        profileRoles: [] as string[],
    } as ITeamState,
    reducers: {
        updateTeamData: (state: ITeamState, action: PayloadAction<TeamDE>) => {
            state.checked = true;
            if (action.payload) {
                if(!state.teams[action.payload.key]){
                    state.teams[action.payload.key] = {roles: [], team: action.payload, user: undefined};
                }else {
                    state.teams[action.payload.key].team.data = action.payload.data;
                    state.teams[action.payload.key].team.version = action.payload.version;
                    state.teams[action.payload.key].team.modified = action.payload.modified;
                }
            }
        },
        populateTeams: (state: ITeamState, action: PayloadAction<TeamListItem[]>) => {
            state.checked = true;
            if (action.payload) {
                state.teamList = [];
                for (let i = 0; i < action.payload.length; i++) {
                    state.teams[action.payload[i].team.key] = action.payload[i];
                    state.teamList.push(action.payload[i].team.key);
                }

            }
        },
        populateRoles: (state: ITeamState, action: PayloadAction<RoleDE[]>) => {
            if (action.payload) {
                state.roles = action.payload.map(role => ({
                    role,
                    permissionTreeRoot: PermissionTree.build(role),
                }) as RoleAndPermissionTree);
            }
        },
        populateProfileRoles: (state: ITeamState, action: PayloadAction<string[]>) => {
            if (action.payload) {
                const roles: Set<RoleDE> = new Set<RoleDE>();
                for (let i = 0; i < state.roles.length; i++) {
                    if (action.payload.includes(state.roles[i].role.data.name)) {
                        roles.add(state.roles[i].role);
                    }
                }
                state.profileRoles = action.payload;
                state.allRoles = PermissionTree.build(roles);
            }
        },
        selectTeam: (state, action: PayloadAction<string>) => {
            if (action.payload) {
                state.selected = action.payload;
            }
        }
    },
});

export async function updateTeamDataRequest(teamKey: string, dispatch: Dispatch) {
    const response = await getTeam(teamKey);
    return dispatch({
        type: updateTeamData.type,
        payload: response
    });
}

export async function populateTeamsRequest(dispatch: Dispatch) {
    const response = await getTeams();
    return dispatch({
        type: populateTeams.type,
        payload: response
    });
}

export async function populateRolesRequest(team: string, dispatch: Dispatch) {
    const response = await getRoles(team);
    return dispatch({
        type: populateRoles.type,
        payload: response
    });
}

export async function populateProfileRolesRequest(user: string, team: string, dispatch: Dispatch) {
    const response = await getProfileRoles(team);
    return dispatch({
        type: populateProfileRoles.type,
        payload: response
    });
}

export const {populateTeams, selectTeam, populateRoles, populateProfileRoles, updateTeamData} = teamsSlice.actions;

export default teamsSlice.reducer;

import {
    AuthorContext,
    CategoryContext, DefaultPermissionType,
    IdeaContext,
    PermissionContext,
    PermissionType,
    ProfileContext
} from "./type";
import {atleastOneInList} from "./index";
import {PermissionTree} from "../../services/PermissionTree";


export const viewIdea = {
    perm: "team.permission.idea.view",
    depends: [],
    description: "Ability for a user to view ideas.",
    title: "View Ideas and Revisions",
    admin: "team.admin.permission.idea.view",
    check({category}, {tree, roles}): boolean {
        const permAccept = PermissionTree.hasOnePermissions(tree,[this.perm]);
        return (
            category.data.viewRoles.length === 0 ||
            atleastOneInList(category.data.viewRoles, roles)
        ) && permAccept;
    }
} as PermissionType<IdeaContext & CategoryContext>;

export const createIdea = {
    perm: "team.permission.idea.create",
    depends: [viewIdea],
    description: "Ability for a user to create ideas and revisions to ideas.",
    title: "Create Ideas and Revisions",
    admin: "team.admin.permission.idea.create",
    check({category}, {tree, roles}): boolean {
        const permAccept = PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
        return (
            category.data.createIdeaRoles.length === 0 ||
            atleastOneInList(category.data.createIdeaRoles, roles)
        ) && permAccept;
    }
} as PermissionType<CategoryContext>;

export const updateIdea = {
    perm: "team.permission.idea.update",
    depends: [viewIdea],
    description: "Ability for a user to update their ideas.",
    title: "Update Ideas and Revisions",
    admin: "team.admin.permission.idea.update",
    check({profile, author}, {tree, roles}): boolean {
        const userCanPublishOwnIdea = profile.key === author.key && PermissionTree.hasPerm(tree, this.perm);
        return PermissionTree.hasPerm(tree,this.admin) || userCanPublishOwnIdea;
    }
} as PermissionType<ProfileContext & AuthorContext>;

export const unPublishIdea = {
    perm: "team.permission.idea.unpublish",
    depends: [viewIdea],
    description: "Ability for a user to unpublish their ideas.",
    title: "Un-Publish Ideas and Revisions",
    admin: "team.admin.permission.idea.unpublish",
    check(_, {tree, roles}): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const publishIdea = {
    perm: "team.permission.idea.publish",
    depends: [viewIdea],
    description: "Ability for a user to publish their ideas.",
    title: "Publish Ideas and Revisions",
    admin: "team.admin.permission.idea.publish",
    check({profile, author}, {tree, roles}): boolean {
        const userCanPublishOwnIdea = profile.key === author.key && PermissionTree.hasPerm(tree,this.perm);
        return PermissionTree.hasPerm(tree,this.admin) || userCanPublishOwnIdea;
    }
} as PermissionType<ProfileContext & AuthorContext>;

export const processIdea = {
    perm: "team.permission.idea.process",
    depends: [viewIdea],
    description: "Ability for a user to process ideas through OpenAI.",
    title: "Process Ideas using AI",
    admin: "team.admin.permission.idea.process",
    check({category}, permission: PermissionContext): boolean {
        const permAccept = PermissionTree.hasOnePermissions(permission.tree,[this.perm, this.admin]);
        return (
            !category.data?.processIdeaRoles ||
            category.data?.processIdeaRoles?.length === 0 ||
            atleastOneInList(category.data?.processIdeaRoles, permission.roles)
        ) && permAccept;
    }
} as PermissionType<CategoryContext>;

export const recommendedIdea = {
    perm: "team.permission.idea.recommended",
    depends: [viewIdea],
    description: "Ability for a user to have OpenAI generate recommendations for an idea.",
    title: "Generate Recommendations with AI",
    admin: "team.admin.permission.idea.recommended",
    check({category}, {tree, roles}): boolean {
        const permAccept = PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
        return (
            !category.data?.recommendedIdeaRoles ||
            category.data?.recommendedIdeaRoles?.length === 0 ||
            atleastOneInList(category.data?.recommendedIdeaRoles, roles)
        ) && permAccept;
    }
} as PermissionType<CategoryContext>;

export const listIdea = {
    perm: "team.permission.idea.list",
    depends: [],
    description: "Ability for a user to get a list of ideas.",
    title: "List Ideas and Revisions",
    admin: "team.admin.permission.idea.list",
    check({category}, {tree, roles}): boolean {
        const permAccept = PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
        return (
            category.data.viewRoles.length === 0 ||
            atleastOneInList(category.data.viewRoles, roles)
        ) && permAccept;
    }
} as PermissionType<CategoryContext>;

export const hideIdea = {
    perm: "team.permission.idea.hide",
    depends: [],
    description: "Ability for a user to hide ideas.",
    title: "Hide Ideas",
    admin: "team.admin.permission.idea.hide",
    check(_, {tree, roles}): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const unHideIdea = {
    perm: "team.permission.idea.unhide",
    depends: [],
    description: "Ability for a user to unHide ideas.",
    title: "UnHide Ideas",
    admin: "team.admin.permission.idea.unhide",
    check(_, {tree, roles}): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const voteIdea = {
    perm: "team.permission.idea.vote",
    depends: [viewIdea],
    description: "Ability for a user to vote for ideas.",
    title: "Vote Ideas and Revisions",
    admin: "team.admin.permission.idea.vote",
    check({category}, {tree, roles}): boolean {
        const permAccept = PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
        return (
            category.data.voteIdeaRoles.length === 0 ||
            atleastOneInList(category.data.voteIdeaRoles, roles)
        ) && permAccept;
    }
} as PermissionType<CategoryContext>;


export const ideaPerms = {
    viewIdea,
    unPublishIdea,
    publishIdea,
    processIdea,
    listIdea,
    voteIdea,
    recommendedIdea,
    updateIdea,
    createIdea,
    hideIdea,
    unHideIdea
}

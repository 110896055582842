import {DefaultPermissionType, PermissionContext, PermissionType} from "./type";
import {PermissionTree} from "../../services/PermissionTree";

export const createCategory = {
    perm: "team.category.create",
    depends: [],
    description: "Ability for a user to create categories.",
    title: "Create Category",
    admin: "team.admin.category.create",
    check(_, {tree, roles} ): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const updateCategory = {
    perm: "team.category.update",
    depends: [],
    description: "Ability for a user to update categories.",
    title: "Update Categories",
    admin: "team.admin.category.update",
    check(_, {tree, roles} ): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const categoryPerms = {
    createCategory,
    updateCategory
};
import {useGlobalDispatch, useGlobalSelector} from "../stores/global.hooks";
import {useEffect, useState} from "react";
import {setSubtitle, setTitle} from "../stores/global.reducer";
import {createIdea} from "../services/APIRequests";
import MarkdownEditor from '@uiw/react-markdown-editor';
import remarkMath from "remark-math";
import MarkdownPreview from '@uiw/react-markdown-preview';
import {IIdea} from "../services/API/IdeaDE";
import {PageTemplate} from "./page";
import {useNavigate} from "react-router-dom";


export const generateMarkdown = (description: string) =>{
    return <MarkdownPreview remarkPlugins={[remarkMath]} source={description} />
}

export const CreateIdeaPage = () => {
    const navigate = useNavigate();
    const [summary, setSummary] = useState( "" );
    const [body, setBody] = useState("" );
    const [title, setStateTitle] = useState(""  );
    const teamInfo = useGlobalSelector(state=>state.teams);
    const teamData = teamInfo.teams[teamInfo.selected];
    const categoryInfo = useGlobalSelector(state=>state.categories);
    const categoryData = categoryInfo.categories[categoryInfo.selected];
    const dispatch = useGlobalDispatch();
    useEffect(()=>{
        dispatch({
            type: setTitle.type,
            payload: "Create Idea"
        });
        dispatch({
            type: setSubtitle.type,
            payload: "Create a new idea for "+categoryData?.data?.name
        });
    }, []);
    return (
        <PageTemplate key={"page"} valKey={"createIdea"}>
            <div className="container">
                <div className="nav">
                    <div className="nav__thread" style={{justifyContent: "flex-start", width:"100%"}}>
                        <a style={{fontSize: 13, paddingLeft: 10, paddingRight: 10}} onClick={()=>navigate("/team/"+teamData.team.key+"/")} className="">{teamData.team.data.name}</a> /
                        <a style={{fontSize: 13, paddingLeft: 10, paddingRight: 10}} onClick={()=>navigate("/team/"+teamData.team.key+"/category/"+categoryData.key+"/")} className="">{categoryData.data.name}</a> /
                        <span style={{fontSize: 13, fontStyle:"italic", paddingLeft: 10}}>Create New Idea</span>
                    </div>
                </div>
                <div className="create">
                    <div className="create__head">
                        <div className="create__title"><img src="/fonts/icons/main/Message.svg" alt="New team"/>Create New Idea</div>
                        <span></span>
                    </div>
                    <div className="create__section create__textarea">
                        <label className="create__label" htmlFor="description">Description</label>
                        <MarkdownEditor
                            height={"200px"}
                            theme={"light"}
                            value={body}
                            enablePreview={false}
                            onChange={(e)=>{
                                if(e!=undefined) setBody(e)
                            }}
                        />
                    </div>
                    <div className="create__section create__textarea">
                        <MarkdownPreview style={{background: "#fff"}} remarkPlugins={[remarkMath]} source={body}/>
                    </div>
                    <div className="create__section">
                        <label className="create__label" htmlFor="title">Title</label>
                        <input type="text" className="form-control" id="title" value={title} onChange={e =>setStateTitle(e.target.value)} placeholder="Idea title here"/>
                    </div>
                    <div className="create__section">
                        <label className="create__label" htmlFor="tags">Summary</label>
                        <input type="text" className="form-control" id="tags" value={summary} onChange={e =>setSummary(e.target.value)} placeholder="Sentence summary here"/>
                    </div>
                    {title.length!==0 && body.length!==0?<div className="create__footer" style={{display: "block", textAlign: "right"}}>
                        <a onClick={()=>{
                            if(title.length===0 || body.length===0){
                                return;
                            }
                            createIdea(teamData.team, categoryData, undefined, {
                                body,
                                summary,
                                title
                            } as IIdea).then((idea)=>{
                                if(idea)
                                    document.location = "/team/"+teamData.team.key+"/category/"+categoryData.key+"/idea/"+idea.key
                            })

                        }} className="create__btn-create btn btn--type-02">Create Idea</a>
                    </div>:""}
                </div>
            </div>
        </PageTemplate>
    );
}

import {useGlobalDispatch} from "../stores/global.hooks";
import {useEffect, useState} from "react";
import {setSubtitle, setTitle} from "../stores/global.reducer";
import IImage from "../services/API/IImage";
import {createTeam} from "../services/APIRequests";
import MarkdownEditor from '@uiw/react-markdown-editor';
import remarkMath from "remark-math";
import MarkdownPreview from '@uiw/react-markdown-preview';
import {PageTemplate} from "./page";


export const generateMarkdown = (description: string) =>{
    return <MarkdownPreview remarkPlugins={[remarkMath]} source={description} />
}

export const CreateTeamPage = () => {
    const dispatch = useGlobalDispatch();
    useEffect(()=>{
        dispatch({
            type: setTitle.type,
            payload: "Create Team"
        });
        dispatch({
            type: setSubtitle.type,
            payload: "Create a new team for brainstorming"
        });
    }, []);
    const [tags, setTags] = useState( "" );
    const [description, setDescription] = useState("" );
    const [name, setName] = useState(""  );

    return (
        <PageTemplate key={"page"} valKey={"createTeam"}>
            <div className="container">
                <div className="create">
                    <div className="create__head">
                        <div className="create__title"><img src="/fonts/icons/main/Group.svg" alt="New team"/>Create New Brainstorm Team</div>
                        <span></span>
                    </div>
                    <div className="create__section">
                        <label className="create__label" htmlFor="name">Team Name</label>
                        <input type="text" className="form-control" id="name" value={name} onChange={e =>setName(e.target.value)} placeholder="Team name here"/>
                    </div>
                    <div className="create__section create__textarea">
                        <label className="create__label" htmlFor="description">Description</label>
                        <MarkdownEditor
                            height={"200px"}
                            theme={"light"}
                            value={description}
                            enablePreview={false}
                            onChange={(e)=>{
                                if(e!=undefined) setDescription(e)
                            }}
                        />
                    </div>
                    <div className="create__section create__textarea">
                        <MarkdownPreview style={{background: "#fff"}} remarkPlugins={[remarkMath]} source={description}/>
                    </div>
                    <div className="create__section">
                        <label className="create__label" htmlFor="tags">Team Tags</label>
                        <input type="text" className="form-control" id="tags" value={tags} onChange={e =>setTags(e.target.value)} placeholder="e.g. nature, science"/>
                    </div>
                    <div className="create__footer" style={{display: "block", textAlign: "right"}}>
                        <a onClick={()=>{
                            createTeam({
                                description,
                                icon: {} as IImage,
                                name,
                                tags: tags.trim().split(","),
                                teamSettings: {
                                    canInvite: ["MEMBER"],
                                    percentAccept: 0.5,
                                    percentReject: 0.5
                                }
                            }).then((team)=>{
                                if(team)
                                    document.location = "/team/"+team.key
                            })

                        }} className="create__btn-create btn btn--type-02">Create Team</a>
                    </div>
                </div>
            </div>
        </PageTemplate>
    );
}

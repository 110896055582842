import {useGlobalSelector} from "../../stores/global.hooks";
import TeamListItem from "../../services/API/TeamListItem";
import {NavigateFunction, useNavigate} from "react-router-dom";

const generateTeamList = (navigate: NavigateFunction, teams: {[key:string]:TeamListItem}, selected:string) => {
    const teamList = Object.values(teams);
    teamList.sort((a,b)=>a.team.data.name.localeCompare(b.team.data.name));
    return teamList.map(item=>{
        const url = "/team/"+item.team.key;
        const classNames = ((item.team.key==selected)?" team__btn__selected":"team__btn");
        if(item.team.data.icon && item.team.data.icon.url){
            return <a key={"listItem"+item.team.key} onClick={()=>navigate(url)} className={classNames} style={{fontSize:"35px", backgroundImage: "url('"+item.team.data.icon.url+"')"}}></a>
        }else{
            return <a key={"listItem"+item.team.key} onClick={()=>navigate(url)} className={classNames} style={{fontSize:"35px"}}><img src={"/fonts/icons/avatars/"+item.team.data.name[0]?.toUpperCase()+".svg"}/></a>
        }
    });
}

export const LeftMenu = () => {
    const navigate = useNavigate();
    const sessionUser = useGlobalSelector(state => state.profile);
    const teamInfo = useGlobalSelector(state => state.teams);
    return (
        <>
            <div className={"team__top"} >
                <a onClick={()=>navigate("/user/profile/"+sessionUser.profile.key)} className={"team__btn"} style={{fontSize:"35px", width: '100%', height:50, backgroundImage: "url('"+sessionUser.profile.data.avatar.url+"')", backgroundPosition: 'center'}}></a>
            </div>
            <div className={"team__top"} style={{flexGrow:"1"}}>
                {teamInfo.checked?generateTeamList(navigate, teamInfo.teams, teamInfo.selected):""}
            </div>
            <div className={"team__bottom"}>
                <a onClick={()=>navigate("/create/team")} className={"team__btn"}><i style={{fontSize:"32px"}} className={"icon-add_circle"}></i></a>
            </div>
        </>
    );
}

import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {v4 as uuidv4} from 'uuid';
import {Dispatch} from "@reduxjs/toolkit";

export const globalSlice = createSlice({
    name: 'global',
    initialState: {
        title: "",
        subtitle: "",
        id: uuidv4(),
        loading: false,
    },
    reducers: {
        setTitle: (state, action: PayloadAction<string>) => {
            state.title = action.payload;
        },
        setSubtitle: (state, action: PayloadAction<string>) => {
            state.subtitle = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        }
    },
});


export const {setTitle, setSubtitle, setLoading} = globalSlice.actions;

export const showLoading = (dispatch: Dispatch | undefined) => {
    if(!dispatch) return;
    dispatch({
        type: setLoading.type,
        payload: true
    })
}


export const hideLoading = (dispatch: Dispatch | undefined, request:Promise<any> | undefined = undefined): Promise<any> | undefined => {

    if(!dispatch) {
        if (request)
            return request;
        return;
    }
    if (request) {
        return request.then((x) => {
            dispatch({
                type: setLoading.type,
                payload: false
            })
            return x;
        });
    }else{
        dispatch({
            type: setLoading.type,
            payload: false
        })
    }
}


export default globalSlice.reducer;

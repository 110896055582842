import {createBrowserRouter, RouteObject} from "react-router-dom";
import React, {createRef} from "react";
import {TeamPage} from "../pages/TeamPage";
import {useGlobalDispatch, useGlobalSelector} from "../stores/global.hooks";
import {
    populateProfileRolesRequest,
    populateRolesRequest,
    populateTeamsRequest,
    selectTeam
} from "../stores/team.reducer";
import {populateCategoriesRequest, selectCategory} from "../stores/category.reducer";
import {CreateTeamPage} from "../pages/CreateTeam";
import {CreateCategoryPage} from "../pages/CreateCategory";
import {CategoryPage} from "../pages/CategoryPage";
import {populateIdeaTreeRequest, populateIdeaRequestSubIdea} from "../stores/idea.reducer";
import {CreateIdeaPage} from "../pages/CreateIdea";
import {IdeaPage} from "../pages/IdeaPage";
import {CreateReviseIdeaPage} from "../pages/CreateReviseIdea";
import {EditCategoryPage} from "../pages/EditCategory";
import {EditTeamPage} from "../pages/EditTeam";
import {SettingTeamPage} from "../pages/SettingTeamPage";
import {RoleTeamPage} from "../pages/RoleTeamPage";
import {InvitePage} from "../pages/InvitePage";
import {acceptedInvite, getInvite, getUser, listInvites, viewInvite} from "../services/APIRequests";
import {InviteList} from "../pages/InviteList";
import {CreateInvite} from "../pages/CreateInvite";
import {EditInvite} from "../pages/EditInvite";
import {InviteAcceptedList} from "../pages/InviteAcceptedList";
import {ProfilePage} from "../pages/ProfilePage";
import UserDE from "../services/API/UserDE";
import {Dispatch} from "@reduxjs/toolkit";
import {PageTemplate} from "../pages/page";
import {hideLoading, setLoading, showLoading} from "../stores/global.reducer";




export const IndexRoutes = (dispatch?: Dispatch, profile?: {checked: boolean, isProfile: boolean, profile: UserDE})=> {

    return [
        {
            path: "/",
            loader: async () => {
                return dispatch? populateTeamsRequest(dispatch) : "";
            },
            children: [
                {
                    path: "/",
                    element: <PageTemplate children={""} valKey={"index"}/>,
                    nodeRef: createRef()
                },
                {
                    path: "/user/profile/:userKey",
                    element: <ProfilePage/>,
                    loader: async ({params}) => {
                        showLoading(dispatch);
                        return hideLoading(dispatch, getUser(params.userKey as string))
                    },
                    nodeRef: createRef()
                },
                {
                    path: "/invite/:inviteKey/",
                    loader: async ({params}) =>{
                        showLoading(dispatch);
                        return hideLoading(dispatch, getInvite(params.inviteKey))
                    },
                    element: <InvitePage />,
                    nodeRef: createRef()
                },
                {
                    path: "/team/:teamKey/",
                    loader: async ({params}) => {
                        if(dispatch && profile) {
                            showLoading(dispatch);
                            await populateRolesRequest(params.teamKey as string, dispatch);
                            dispatch({
                                type: selectTeam.type,
                                payload: params.teamKey
                            });
                            await populateProfileRolesRequest(profile.profile.key, params.teamKey as string, dispatch);
                            return hideLoading(dispatch, populateCategoriesRequest(params.teamKey as string, dispatch));
                        }
                    },
                    children: [
                        {
                            path: "/team/:teamKey/",
                            element: <TeamPage/>,
                            nodeRef: createRef()
                        },
                        {
                            path: "/team/:teamKey/create/category",
                            element: <CreateCategoryPage/>,
                            nodeRef: createRef()
                        },
                        {
                            path: "/team/:teamKey/invites",
                            element: <InviteList/>,
                            loader: async ({params}) =>{
                                showLoading(dispatch);
                                return hideLoading(dispatch, listInvites(params.teamKey as string));
                            },
                            nodeRef: createRef()
                        },

                        {
                            path: "/team/:teamKey/invite/create",
                            element: <CreateInvite />,
                            nodeRef: createRef()
                        },
                        {
                            path: "/team/:teamKey/invite/:inviteKey",
                            element: <EditInvite />,
                            loader: async ({params}) => {
                                showLoading(dispatch);
                                return hideLoading(dispatch, viewInvite(params.teamKey as string, params.inviteKey as string))
                            },
                            nodeRef: createRef()
                        },
                        {
                            path: "/team/:teamKey/invite/:inviteKey/accepted",
                            element: <InviteAcceptedList />,
                            loader: async ({params}) => {
                                showLoading(dispatch);
                                await hideLoading(dispatch, acceptedInvite(params.inviteKey as string, params.teamKey as string))
                            },
                            nodeRef: createRef()
                        },
                        {
                            path: "/team/:teamKey/edit",
                            element: <EditTeamPage />,
                            nodeRef: createRef()
                        },
                        {
                            path: "/team/:teamKey/settings",
                            element: <SettingTeamPage />,
                            nodeRef: createRef()
                        },
                        {
                            path: "/team/:teamKey/roles",
                            element: <RoleTeamPage />,
                            nodeRef: createRef()
                        },
                        {
                            path: "/team/:teamKey/category/:categoryKey/",
                            loader: async ({params}) => {
                                if(dispatch) {
                                    showLoading(dispatch)
                                    dispatch({
                                        type: selectCategory.type,
                                        payload: params.categoryKey
                                    });
                                    return hideLoading(dispatch, populateIdeaRequestSubIdea(params.teamKey as string, params.categoryKey as string, undefined, 0, dispatch));
                                }
                            },
                            children: [
                                {
                                    path: "/team/:teamKey/category/:categoryKey/",
                                    element: <CategoryPage type={"published"}/>,
                                    nodeRef: createRef()
                                },
                                {
                                    path: "/team/:teamKey/category/:categoryKey/edit",
                                    element: <EditCategoryPage />,
                                    nodeRef: createRef()
                                }
                            ]
                        },
                        {
                            path: "/team/:teamKey/category/:categoryKey/create/idea",
                            element: <CreateIdeaPage/>,
                            loader: async ({params}) => {
                                if(dispatch) {
                                    showLoading(dispatch)
                                    dispatch({
                                        type: selectCategory.type,
                                        payload: params.categoryKey
                                    });
                                    hideLoading(dispatch);
                                }
                                return "";
                            },
                            nodeRef: createRef()
                        },
                        {
                            path: "/team/:teamKey/category/:categoryKey/unpublished/",
                            loader: async ({params}) => {
                                if(dispatch) {
                                    showLoading(dispatch)
                                    dispatch({
                                        type: selectCategory.type,
                                        payload: params.categoryKey
                                    });
                                    return hideLoading(dispatch, populateIdeaRequestSubIdea(params.teamKey as string, params.categoryKey as string, undefined, 2, dispatch));
                                }
                            },
                            children: [
                                {
                                    path: "/team/:teamKey/category/:categoryKey/unpublished/",
                                    element: <CategoryPage type={"unpublished"}/>,
                                    nodeRef: createRef()
                                }
                            ]
                        },
                        {
                            path: "/team/:teamKey/category/:categoryKey/hidden/",
                            loader: async ({params}) => {
                                if(dispatch) {
                                    showLoading(dispatch)
                                    dispatch({
                                        type: selectCategory.type,
                                        payload: params.categoryKey
                                    });
                                    return hideLoading(dispatch, populateIdeaRequestSubIdea(params.teamKey as string, params.categoryKey as string, undefined, 1, dispatch))
                                }
                            },
                            children: [
                                {
                                    path: "/team/:teamKey/category/:categoryKey/hidden/",
                                    element: <CategoryPage type={"hidden"}/>,
                                    nodeRef: createRef()
                                }
                            ]
                        },
                        {
                            path: "/team/:teamKey/category/:categoryKey/idea/:ideaKey/",
                            loader: async ({params}) => {
                                if(dispatch) {
                                    showLoading(dispatch)
                                    dispatch({
                                        type: selectCategory.type,
                                        payload: params.categoryKey
                                    });
                                    await hideLoading(dispatch, populateIdeaTreeRequest(params.teamKey as string, params.categoryKey as string, params.ideaKey as string, dispatch));
                                    return params;
                                }
                            },
                            children: [
                                {
                                    path: "/team/:teamKey/category/:categoryKey/idea/:ideaKey/",
                                    element: <IdeaPage/>,
                                    nodeRef: createRef()
                                },
                                {
                                    path: "/team/:teamKey/category/:categoryKey/idea/:ideaKey/create/idea",
                                    element: <CreateReviseIdeaPage/>,
                                    // @ts-ignore
                                    nodeRef: createRef(),
                                    loader: async ({params}) => {
                                        showLoading(dispatch)
                                        hideLoading(dispatch);
                                        return params;
                                    }
                                }
                            ] as RouteObject[]
                        },
                    ] as RouteObject[]
                },

                {
                    path: "/create/team/",
                    element: <CreateTeamPage/>,
                    nodeRef: createRef()
                },
            ]
        }
    ] as RouteObject[];
}

const recursiveFlat = (r: RouteObject[] | undefined): RouteObject[] => {
    if(!r) return [];
    const items: RouteObject[] = [];
    for (let i = 0; i < r.length; i++) {
        const childs = recursiveFlat(r[i].children);
        for (let j = 0; j < childs.length; j++) {
            items.push(childs[j]);
        }
        items.push(r[i]);
    }
    return items;
}

export const routes = (() => {
    return [...recursiveFlat(IndexRoutes())];
})();
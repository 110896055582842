import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import App from "./App";
import {Provider} from "react-redux";
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import ga from 'javascript-time-ago/locale/ga.json'

import globalStore from './stores/global';
import { StompSessionProvider } from 'react-stomp-hooks';
import {hostName} from "./services/APIRequests";
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);



TimeAgo.addDefaultLocale(en)
TimeAgo.addLocale(ga)

root.render(
    <React.StrictMode>
        <Provider store={globalStore}>
            <StompSessionProvider
                url={`https://${hostName}/messages`}
                //All options supported by @stomp/stompjs can be used here
            >
                <App/>
            </StompSessionProvider>
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


import {useGlobalDispatch, useGlobalSelector} from "../stores/global.hooks";
import {Fragment, useCallback, useEffect, useRef, useState} from "react";
import {setSubtitle, setTitle} from "../stores/global.reducer";
import {createIdea, createTeam, rootParent} from "../services/APIRequests";
import MarkdownEditor from '@uiw/react-markdown-editor';
import remarkMath from "remark-math";
import MarkdownPreview from '@uiw/react-markdown-preview';
import IdeaDE, {IIdea} from "../services/API/IdeaDE";
import {Params, useLoaderData, useNavigate} from "react-router-dom";
import {PageTemplate} from "./page";


export const generateMarkdown = (description: string) =>{
    return <MarkdownPreview remarkPlugins={[remarkMath]} source={description} />
}

export const CreateReviseIdeaPage = () => {
    const navigate = useNavigate();
    const dispatch = useGlobalDispatch();
    useEffect(()=>{
        dispatch({
            type: setTitle.type,
            payload: "Create Idea"
        });
        dispatch({
            type: setSubtitle.type,
            payload: "Create a new idea for "+categoryData.data.name
        });
        if(ideaData){
            rootParent(teamData.team.key, categoryData.key, ideaData.idea.idea.key).then(d=>{
                if(d){
                    setRootIdea(d);
                }
            })
        }
    }, []);

    const params = useLoaderData() as {ideaKey:string};

    const [summary, setSummary] = useState( "" );
    const [body, setBody] = useState("" );
    const [title, setStateTitle] = useState(""  );
    const teamInfo = useGlobalSelector(state=>state.teams);
    const teamData = teamInfo.teams[teamInfo.selected];
    const categoryInfo = useGlobalSelector(state=>state.categories);
    const categoryData = categoryInfo.categories[categoryInfo.selected];
    const ideaInfo = useGlobalSelector(state=>state.ideas);
    const ideaData = {idea: ideaInfo.ideaRef[params.ideaKey]};
    const [rootIdea, setRootIdea] = useState( ideaData.idea );
    return (
        <PageTemplate key={"page"} valKey={"createReviseIdea"}>
            <div className="container">
                <div className="nav">
                    <div className="nav__thread" style={{justifyContent: "flex-start", width:"100%"}}>
                        <a style={{fontSize: 13, paddingLeft: 10, paddingRight: 10}} onClick={()=>navigate("/team/"+teamData.team.key+"/")} className="">{teamData.team.data.name}</a> /
                        <a style={{fontSize: 13, paddingLeft: 10, paddingRight: 10}} onClick={()=>navigate("/team/"+teamData.team.key+"/category/"+categoryData.key+"/")} className="">{categoryData.data.name}</a> /
                        <a style={{fontSize: 13, paddingLeft: 10, paddingRight: 10}} onClick={()=>navigate("/team/"+teamData.team.key+"/category/"+categoryData.key+"/idea/"+rootIdea.idea.key+"/")} className="">{rootIdea.idea.data.title}</a> /
                        <span style={{fontSize: 13, fontStyle:"italic", paddingLeft: 10}}>Create New Idea Revision</span>
                    </div>
                </div>
                <div style={{background: "#fff", padding:20, marginTop:20, borderRadius:10}}>
                    <h6>Revision for "{ideaData.idea.idea.data.title}"</h6>
                    {generateMarkdown(ideaData.idea.idea.data.body)}
                </div>
                <div className="create">
                    <div className="create__head">
                        <div className="create__title"><img src="/fonts/icons/main/Message.svg" alt="New team"/>Create New Revision Idea</div>
                        <span>{ideaData.idea.idea.data.title}</span>
                    </div>
                    <div className="create__section create__textarea">
                        <label className="create__label" htmlFor="description">Description</label>
                        <MarkdownEditor
                            height={"200px"}
                            theme={"light"}
                            value={body}
                            enablePreview={false}
                            onChange={(e)=>{
                                if(e!=undefined) setBody(e)
                            }}
                        />
                    </div>
                    <div className="create__section create__textarea">
                        <MarkdownPreview style={{background: "#fff"}} remarkPlugins={[remarkMath]} source={body}/>
                    </div>
                    <div className="create__footer" style={{display: "block", textAlign: "right"}}>
                        <a onClick={()=>{
                            createIdea(teamData.team, categoryData, ideaData.idea.idea, {
                                body,
                                summary,
                                title
                            } as IIdea).then((idea)=>{
                                if(idea)
                                    document.location = "/team/"+teamData.team.key+"/category/"+categoryData.key+"/idea/"+rootIdea.idea.key+"#"+idea.key
                            })

                        }} className="create__btn-create btn btn--type-02">Create Revision Idea</a>
                    </div>
                </div>
            </div>
        </PageTemplate>
    );
}

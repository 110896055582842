import MarkdownPreview from "@uiw/react-markdown-preview";
import remarkMath from "remark-math";
import {useEffect, useState} from "react";
import {useGlobalDispatch, useGlobalSelector} from "../stores/global.hooks";
import {setSubtitle, setTitle} from "../stores/global.reducer";
import MarkdownEditor from "@uiw/react-markdown-editor";
import IImage from "../services/API/IImage";
import Slider from '@uiw/react-slider';
import {updateTeam} from "../services/APIRequests";
import {PageTemplate} from "./page";
import {useNavigate} from "react-router-dom";

export const generateMarkdown = (description: string) =>{
    return <MarkdownPreview remarkPlugins={[remarkMath]} source={description} />
}

export const EditTeamPage = () => {
    const navigate = useNavigate();
    const dispatch = useGlobalDispatch();
    useEffect(()=>{
        dispatch({
            type: setTitle.type,
            payload: "Edit Team"
        });
        dispatch({
            type: setSubtitle.type,
            payload: ""
        });
    }, []);
    const teamInfo = useGlobalSelector(state=>state.teams);
    const teamData = teamInfo.teams[teamInfo.selected];

    const [tags, setTags] = useState( teamData.team.data.tags.join(", ") );
    const [description, setDescription] = useState(teamData.team.data.description);
    const [name, setName] = useState(teamData.team.data.name  );

    return (
        <PageTemplate key={"page"} valKey={"editTeam"}>
            <div className="container">
                <div className="nav">
                    <div className="nav__thread" style={{justifyContent: "flex-start", width:"100%"}}>
                        <a style={{fontSize: 13, paddingLeft: 10, paddingRight: 10}} onClick={()=>navigate("/team/"+teamData.team.key+"/")} className="">{teamData.team.data.name}</a> /
                        <span style={{fontSize: 13, fontStyle:"italic", paddingLeft: 10}}>Edit Team</span>
                    </div>
                </div>
                <div className="create">
                    <div className="create__head">
                        <div className="create__title"><img src="/fonts/icons/main/Group.svg" alt="New team"/>Edit Team</div>
                        <span></span>
                    </div>
                    <div className="create__section">
                        <label className="create__label" htmlFor="name">Team Name</label>
                        <input type="text" className="form-control" id="name" value={name} onChange={e =>setName(e.target.value)} placeholder="Team name here"/>
                    </div>
                    <div className="create__section create__textarea">
                        <label className="create__label" htmlFor="description">Description</label>
                        <MarkdownEditor
                            height={"200px"}
                            theme={"light"}
                            value={description}
                            enablePreview={false}
                            onChange={(e)=>{
                                if(e!=undefined) setDescription(e)
                            }}
                        />
                    </div>
                    <div className="create__section create__textarea">
                        <MarkdownPreview style={{background: "#fff"}} remarkPlugins={[remarkMath]} source={description}/>
                    </div>
                    <div className="create__section">
                        <label className="create__label" htmlFor="tags">Team Tags</label>
                        <input type="text" className="form-control" id="tags" value={tags} onChange={e =>setTags(e.target.value)} placeholder="e.g. nature, science"/>
                    </div>

                    {name.length>2?<div className="create__footer" style={{display: "block", textAlign: "right"}}>
                        <a onClick={()=>{
                            if(name.length<=2) return;
                            updateTeam(teamData.team, {
                                ...teamData.team.data,
                                description,
                                name,
                                tags: tags.split(",").map(tag=>tag.trim()),
                            }).then((team)=>{
                                if(team)
                                    document.location = "/team/"+team.key
                            })

                        }} className="create__btn-create btn btn--type-02">Save Team</a>
                    </div>:""}
                </div>
            </div>
        </PageTemplate>
    );
}

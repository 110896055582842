import {useGlobalSelector} from "../stores/global.hooks";
import {createInvite, createTeam, denyPermission, grantPermission} from "../services/APIRequests";
import {useState} from "react";
import {IInvite} from "../services/API/InviteDE";
import Switch from "@uiw/react-switch";
import Select from '@uiw/react-select';
import {PageTemplate} from "./page";

export const CreateInvite = ()=>{
    const teamInfo = useGlobalSelector(state=>state.teams);
    const teamData = teamInfo.teams[teamInfo.selected];

    const [domain, setDomain] = useState("");
    const [source, setSource] = useState("");
    const [invites, setInvites] = useState(0);
    const [role, setRole] = useState("");
    return (
        <PageTemplate key={"page"} valKey={"createInvite"}>
            <div className="container">
                <div className="create">
                    <div className="create__head">
                        <div className="create__title"><img src="/fonts/icons/main/Group.svg" alt="New team"/>Create Invite</div>
                        <span></span>
                    </div>
                    <div className="create__section">
                        <label className="create__label" htmlFor="domain">Domain <span style={{fontStyle:"italic", fontSize:12}}>(blank for any domain)</span></label>
                        <input type="text" className="form-control" id="domain" value={domain} onChange={e =>setDomain(e.target.value)} placeholder="e.g. gmail.com"/>
                    </div>
                    <div className="create__section">
                        <label className="create__label" htmlFor="source">Source</label>
                        <Select value={source} defaultValue={""} onChange={e =>setSource(e.target.value)} >
                            <Select.Option value={""}>Any</Select.Option>
                            <Select.Option value={"google"}>Google</Select.Option>
                            <Select.Option value={"azure"}>Azure</Select.Option>
                            <Select.Option value={"github"}>Github</Select.Option>
                        </Select>
                    </div>
                    <div className="create__section">
                        <label className="create__label" htmlFor="invites">Number of invites</label>
                        <input type="text" className="form-control" id="invites" value={invites} onChange={e =>{
                            const newVal = parseInt(e.target.value);
                            setInvites( isNaN(newVal)? -1:newVal<-1?-1:newVal);
                        }} placeholder="e.g. nature, science"/>
                    </div>
                    <div className="create__section">
                        <label className="create__label" style={{fontSize:18, fontStyle:"italic", borderBottom:"#f0f0f0 1px solid", marginBottom:5}}>Invite user as a: </label>
                        {teamInfo.profileRoles.map((v)=>
                            <div className="create__section create__textarea" style={{display:"flex", margin:0, marginBottom:5, background:"#f8f8f8", flexDirection:"row",padding:10}}>
                                <div style={{flexGrow:"1"}}>
                                    <p style={{padding:0, fontSize:15, margin:0, color:"#000"}}>{v}</p>
                                </div>
                                <div>
                                    <Switch
                                        checked={role===v}
                                        onClick={(e)=>setRole(v)}
                                    />
                                </div>
                            </div>)}
                    </div>
                    <div className="create__footer" style={{display: "block", textAlign: "right"}}>
                        <a onClick={()=>{
                            createInvite(teamData.team.key, role, {
                                domain: domain==""?null:domain,
                                source: source==""?null:source,
                                invites: invites<-1?-1:invites,
                            } as IInvite).then(()=>{
                                document.location = "/team/"+teamData.team.key+"/invites";
                            })

                        }} className="create__btn-create btn btn--type-02">Create Invite</a>
                    </div>
                </div>
            </div>
        </PageTemplate>
    );
}

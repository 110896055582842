import {createSlice, Dispatch, PayloadAction} from "@reduxjs/toolkit";
import {
    getHiddenIdeas,
    getIdea,
    getIdeas,
    getUnpublishedIdeas, ideaIdTree, ideaTree,
    rootParent
} from "../services/APIRequests";
import IdeaDE from "../services/API/IdeaDE";
import IdeaListItem from "../services/API/IdeaListItem";
import IdeaTree, {IdeaIdTree} from "../services/API/IdeaTree";

export interface IdeaMapEntry {
    idea: IdeaListItem,
    children: IdeaMap
}
export interface IdeaMap {
    [key:string]: IdeaMapEntry
}

export interface IdeaAction {
    ideas: IdeaListItem[];
    parent: IdeaDE;
}
export interface IState {
    checked: boolean;
    ideas: IdeaMap;
    ideaRef: {[key:string]: IdeaListItem}
    currentTree: IdeaIdTree;
}
const addIdeaToState = (state:IState, ideaListItem: IdeaListItem ) =>{
    const ideaMapEntry = {idea: ideaListItem } as IdeaMapEntry;
    const key = ideaListItem.idea.key;
    state.ideas[key] = ideaMapEntry;
    state.ideaRef[key] = ideaMapEntry.idea;
}
export const ideaSlice = createSlice({
    name: 'global',
    initialState: {
        checked: false,
        ideas: {} as IdeaMap,
        ideaRef: {} as {[key:string]: IdeaListItem},
        currentTree: {} as IdeaIdTree,
    } as IState,
    reducers: {
        populateIdea: (state, action: PayloadAction<{
            response:IdeaListItem;
        }>) => {
            state.checked = true;
            if(action.payload) {
                state.ideas = {};
                addIdeaToState(state, action.payload.response);
            }
        },
        populateTreeIdea: (state, action: PayloadAction<{
            ideaTreeResponse: IdeaIdTree;
        }>) => {
            state.checked = true;
            if(action.payload) {
                state.currentTree = action.payload.ideaTreeResponse
            }
        },
        updateIdea: (state, action: PayloadAction<IdeaListItem>) => {
            if(action.payload) {
                const key = action.payload.idea.key;
                if(state.ideaRef[key]) {
                    state.ideaRef[key].idea = action.payload.idea;
                    state.ideaRef[key].pending = action.payload.pending;
                    state.ideaRef[key].voteResults = action.payload.voteResults;
                    state.ideaRef[key].meta = action.payload.meta;
                    state.ideaRef[key].author = action.payload.author;
                }else{
                    state.ideaRef[key] = action.payload;
                }
            }
        },
        populateIdeaList: (state, action: PayloadAction<IdeaListItem[]>) => {
            state.checked = true;
            if(action.payload) {
                state.ideas = {};
                for (let i = 0; i < action.payload.length; i++) {
                    const children = {} as IdeaMap;
                    const ideaMapEntry = {idea: action.payload[i], children: children} as IdeaMapEntry;
                    state.ideas[action.payload[i].idea.key] = ideaMapEntry;
                }
            }
        },
        populateAppend: (state, action: PayloadAction<IdeaAction>) => {
            if(action.payload) {
            }
        },
        selectIdea: (state, action: PayloadAction<string>) => {
            if(action.payload) {
            }
        }
    },
});



export async function populateIdeaRequestSubIdea(teamKey: string, categoryKey: string, ideaKey: string | undefined, type: number, dispatch: Dispatch) {
    let response;
    switch (type) {
        case 0:
            response = await getIdeas(teamKey, categoryKey, ideaKey?? "");
            break;
        case 1:
            response = await getHiddenIdeas(teamKey, categoryKey, ideaKey?? "");
            break;
        case 2:
            response = await getUnpublishedIdeas(teamKey, categoryKey, ideaKey?? "");
            break;
    }

    return dispatch({
        type: populateIdeaList.type,
        payload: response
    });
}

export async function populateIdeaRequestSubIdeaAppend(teamKey: string, categoryKey: string, ideaKey: string | undefined, type: number, dispatch: Dispatch) {
    let response;
    switch (type) {
        case 0:
            response = await getIdeas(teamKey, categoryKey, ideaKey?? "");
            break;
        case 1:
            response = await getHiddenIdeas(teamKey, categoryKey, ideaKey?? "");
            break;
        case 2:
            response = await getUnpublishedIdeas(teamKey, categoryKey, ideaKey?? "");
            break;
    }
    return dispatch({
        type: populateAppend.type,
        payload: response
    });
}
export async function getIdeaData(teamKey: string, categoryKey: string, ideaKey: string, dispatch: Dispatch) {

    let response = await getIdea(teamKey, categoryKey, ideaKey);
    if(!response) return;
    return dispatch({
        type: updateIdea.type,
        payload: response
    });

}

export async function populateIdeaTreeRequest(teamKey: string, categoryKey: string, ideaKey: string, dispatch: Dispatch) {
    let response = await rootParent(teamKey, categoryKey, ideaKey);
    let ideaTreeResponse: IdeaIdTree | undefined;
    if(!response) {
        ideaTreeResponse = await ideaIdTree(teamKey, categoryKey, ideaKey);
    }else{
        ideaTreeResponse = await ideaIdTree(teamKey, categoryKey, response.idea.key);
    }
    if(!ideaTreeResponse) return;
    return dispatch({
        type: populateTreeIdea.type,
        payload: {
            ideaTreeResponse
        }
    });

}

export const {populateIdea, populateIdeaList, selectIdea, populateAppend, populateTreeIdea, updateIdea} = ideaSlice.actions;

export default ideaSlice.reducer;

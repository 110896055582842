import {DefaultPermissionType, PermissionType} from "./type";
import {PermissionTree} from "../../services/PermissionTree";

export const changeTeamSettings = {
    perm: "team.settings.update",
    depends: [],
    description: "Ability for a user to edit accept and reject percentages as well as which roles can invite a user.",
    title: "Update Team Settings",
    admin: "team.admin.settings.update",
    check(_, {tree, roles}): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const updateTeam = {
    perm: "team.data.update",
    depends: [],
    description: "Ability for a user to update team name, description.",
    title: "Update Team",
    admin: "team.admin.data.update",
    check(_, {tree, roles}): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const inviteToTeam = {
    perm: "team.user.invite",
    depends: [],
    description: "Ability for a user to invite a user to the team.",
    title: "Invite User",
    admin: "team.admin.user.invite",
    check(_, {tree, roles}): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const roleAssignTeamMember = {
    perm: "team.user.role.assign",
    depends: [],
    description: "Ability for a user to assign a user to a role.",
    title: "Assign Role",
    admin: "team.admin.user.role.assign",
    check(_, {tree, roles}): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const roleRevokeTeamMember = {
    perm: "team.user.role.revoke",
    depends: [],
    description: "Ability for a user to revoke a role from a user.",
    title: "Revoke Role",
    admin: "team.admin.user.role.revoke",
    check(_, {tree, roles}): boolean {
        return PermissionTree.hasOnePermissions(tree,[this.perm, this.admin]);
    }
} as DefaultPermissionType;

export const teamPerms = {
    changeTeamSettings,
    updateTeam,
    inviteToTeam,
    roleAssignTeamMember,
    roleRevokeTeamMember
};


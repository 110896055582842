import {useEffect, useState} from "react";
import {useGlobalDispatch, useGlobalSelector} from "../stores/global.hooks";
import {setSubtitle, setTitle} from "../stores/global.reducer";
import {denyPermission, grantPermission, updateTeam, updateTeamSettings} from "../services/APIRequests";
import RoleDE from "../services/API/RoleDE";
import Switch from '@uiw/react-switch';
import {permissions} from "../common/permissons";
import {PageTemplate} from "./page";
import {useNavigate} from "react-router-dom";


export const RoleCreatePage = () => {
    const teamInfo = useGlobalSelector(state=>state.teams);
    const teamData = teamInfo.teams[teamInfo.selected];

    const [editRole, setEditRole] = useState(null as RoleDE|null);

}

export const RoleTeamPage = () => {
    const navigate = useNavigate();
    const dispatch = useGlobalDispatch();
    useEffect(()=>{
        dispatch({
            type: setTitle.type,
            payload: "Edit Team Roles"
        });
        dispatch({
            type: setSubtitle.type,
            payload: ""
        });
    }, []);
    const teamInfo = useGlobalSelector(state=>state.teams);
    const teamData = teamInfo.teams[teamInfo.selected];
    const [editRole, setEditRole] = useState(null as RoleDE|null);
    const [pending, setPending] = useState(false);


    return (
        <PageTemplate key={"page"} valKey={`roleTeam-${teamData.team.key}`}>
            <div className="container">
                <div className="nav">
                    <div className="nav__thread" style={{justifyContent: "flex-start", width:"100%"}}>
                        <a style={{fontSize: 13, paddingLeft: 10, paddingRight: 10}} onClick={()=>navigate("/team/"+teamData.team.key+"/")} className="">{teamData.team.data.name}</a> /
                        <span style={{fontSize: 13, fontStyle:"italic", paddingLeft: 10}}>Edit Team Roles</span>
                    </div>
                </div>
                <div className="create">
                    <div style={{display:"flex", flexDirection: "row"}}>
                        <div style={{width:250}}>
                            <div style={{width:"100%", display:"flex", flexDirection: "row"}}>
                                <div style={{flexGrow:"1"}}>
                                    <h6>Team Roles</h6>
                                </div>
                                <div style={{paddingRight: 20}}>
                                    <a style={{cursor:"pointer"}} onClick={()=>{
                                        setEditRole(null);
                                    }}><i className={"icon-groups"}/></a>
                                </div>
                            </div>

                            <ul>
                                {teamInfo.roles.filter(r=>r.role.data.name!=='ADMINISTRATOR').map(role=>
                                    <li><a style={{cursor:"pointer"}} onClick={()=>{
                                        setEditRole(role.role);
                                    }}>{role.role.data.name}</a></li>
                                )}
                            </ul>
                        </div>
                        {editRole!=null?<div style={{flexGrow:"1"}}>
                            <div className="create__head">
                                <div className="create__title"><img src="/fonts/icons/main/User.svg" alt="New team"/>Role Editing for {editRole.data.name}</div>
                                <span></span>
                            </div>
                            {Object.entries(permissions).map(([k,v])=>
                            <div className="create__section create__textarea" style={{display:"flex", flexDirection:"row",paddingBottom:10, borderBottom: "1px solid #f0f0f0"}}>
                                <div style={{flexGrow:"1"}}>
                                    <p style={{padding:0, margin:0, color:"#000"}}>{v.title}</p>
                                    <p style={{padding:0, margin:0, color:"#7a7a7a"}}>{v.description}</p>
                                </div>
                                <div>
                                    <Switch
                                        checked={editRole.data.permissions.includes(v.perm)}
                                        disabled={pending || !(v.depends.length==0 || v.depends.every(x=>editRole.data.permissions.includes(x.perm))) && !editRole.data.permissions.includes(v.perm)}
                                        onClick={async ()=>{
                                            if(pending) return;
                                            if(editRole.data.permissions.includes(v.perm)){
                                                setPending(true);
                                                const newRole = await denyPermission(teamData.team, editRole.data.name, v.perm);
                                                setPending(false);
                                                if(newRole){
                                                    setEditRole(newRole);
                                                }else{
                                                    alert("error")
                                                }

                                            }
                                            else {
                                                setPending(true);
                                                const newRole = await grantPermission(teamData.team, editRole.data.name, v.perm);
                                                setPending(false);
                                                if(newRole){
                                                    setEditRole(newRole);
                                                }else{
                                                    alert("error")
                                                }
                                            }

                                        }}
                                    />
                                </div>
                            </div>)}
                        </div>:""}
                    </div>
                </div>
            </div>
        </PageTemplate>
    );
}

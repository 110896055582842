import axios from "axios";
import UserDE from "./API/UserDE";
import TeamDE, {ITeam, ITeamSettings} from "./API/TeamDE";
import TeamListItem from "./API/TeamListItem";
import CategoryDE, {ICategory} from "./API/CategoryDE";
import IdeaDE, {IIdea} from "./API/IdeaDE";
import IdeaListItem from "./API/IdeaListItem";
import IdeaTree, {IdeaIdTree} from "./API/IdeaTree";
import RoleDE from "./API/RoleDE";
import InviteMeta from "./API/InviteMeta";
import InviteDE, {IInvite} from "./API/InviteDE";
import Connection from "./API/Connection";
import Cookies from 'js-cookie'


export const hostName = Cookies.get('ad') ?? "api.brainstorm.im";

export const getHostname = () => {

    return axios.get('https://'+hostName, {
        withCredentials: true,
        // @ts-ignore
        crossdomain: true

    });
}
export const getProfile = async (): Promise<UserDE> => {
    const data = await axios.get('https://'+hostName+'/auth/profile', {
        withCredentials: true,
        // @ts-ignore
        crossdomain: true
    });
    return data.data.data;
}

export const getUser = async (userKey: string): Promise<UserDE> => {
    const data = await axios.get(`https://${hostName}/user/view?userKey=${userKey}`, {
        withCredentials: true,
        // @ts-ignore
        crossdomain: true
    });
    return data.data;
}

export const getTeams = async(): Promise<TeamListItem[]> => {
    const data = await axios.get(`https://${hostName}/team/list`, {
        withCredentials: true,
    });
    return data.data.data;
}
export const createIdea = async (team: TeamDE, category: CategoryDE, idea: IdeaDE|undefined, ideaData: IIdea) : Promise<IdeaDE> => {
    const data = await axios.post(`https://${hostName}/idea/create?teamKey=`+team.key+'&categoryKey='+category.key+(idea?.key?'&parentIdeaKey='+idea.key:''), ideaData, {
        withCredentials: true,
    });
    return data.data.data;
}

export const postTags = async(team: TeamDE, idea: IdeaDE): Promise<IdeaDE|undefined> => {
    const data = await axios.post(`https://${hostName}/idea/tags?teamKey=`+team.key+'&ideaKey='+idea.key, {}, {
        withCredentials: true,
    });
    return data.data.data;
}

export const postProcess = async(team: TeamDE, idea: IdeaDE): Promise<IdeaDE|undefined> => {
    const data = await axios.post(`https://${hostName}/idea/process?teamKey=`+team.key+'&ideaKey='+idea.key, {}, {
        withCredentials: true,
    });
    return data.data.data;
}

export const postRecommended = async(team: TeamDE, idea: IdeaDE): Promise<IdeaDE[]|undefined> => {
    const data = await axios.post(`https://${hostName}/idea/recommended?teamKey=`+team.key+'&ideaKey='+idea.key, {}, {
        withCredentials: true,
    });
    return data.data.data;
}

export const createTeam = async (team: ITeam) : Promise<TeamDE> => {
    const data = await axios.post(`https://${hostName}/team/create`, team, {
        withCredentials: true,
    });
    return data.data.data;
}
export const updateTeamSettings = async (team: TeamDE, teamSettings: ITeamSettings) : Promise<TeamDE> => {
    const data = await axios.post(`https://${hostName}/team/settings/update?teamKey=`+team.key, teamSettings, {
        withCredentials: true,
    });
    return data.data.data;
}
export const updateTeam = async (team: TeamDE, teamData: ITeam) : Promise<TeamDE> => {
    const data = await axios.post(`https://${hostName}/team/update?teamKey=`+team.key, teamData, {
        withCredentials: true,
    });
    return data.data.data;
}
export const createCategory = async (team: TeamDE, category: ICategory) : Promise<CategoryDE> => {
    const data = await axios.post(`https://${hostName}/category/create?teamKey=`+team.key, category, {
        withCredentials: true,
    });
    return data.data.data;
}
export const updateCategory = async (team: TeamDE, category: CategoryDE, categoryData: ICategory) : Promise<CategoryDE> => {
    const data = await axios.post(`https://${hostName}/category/update?teamKey=`+team.key+"&categoryKey="+category.key, categoryData, {
        withCredentials: true,
    });
    return data.data.data;
}
export const getTeam = async(teamKey: string|undefined): Promise<TeamDE|undefined> => {
    if(!teamKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/team/?teamKey=`+teamKey, {
        withCredentials: true,
    });
    return data.data.data;
}

export const getRoles = async(teamKey: string|undefined): Promise<RoleDE[]|undefined> => {
    if(!teamKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/team/role/permissions?teamKey=`+teamKey, {
        withCredentials: true,
    });
    return data.data.data;
}
export const getProfileRoles = async(teamKey: string|undefined): Promise<string[]|undefined> => {
    if(!teamKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/team/roles?teamKey=`+teamKey, {
        withCredentials: true,
    });
    return data.data.data;
}

export const grantPermission = async(team: TeamDE, roleName: string, permission: string): Promise<RoleDE> => {
    const data = await axios.post(`https://${hostName}/role/grant?teamKey=${team.key}&roleName=${roleName}&permission=${permission}`, {}, {
        withCredentials: true,
    });
    return data.data.data;
}
export const denyPermission = async(team: TeamDE, roleName: string, permission: string): Promise<RoleDE> => {
    const data = await axios.post(`https://${hostName}/role/deny?teamKey=${team.key}&roleName=${roleName}&permission=${permission}`, {}, {
        withCredentials: true,
    });
    return data.data.data;
}


export const getCategories = async(teamKey: string|undefined): Promise<CategoryDE[]|undefined> => {
    if(!teamKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/category/list?teamKey=`+teamKey, {
        withCredentials: true,
    });
    return data.data.data;
}

export const getCategory = async(teamKey: string, categoryKey: string|undefined): Promise<CategoryDE[]|undefined> => {
    if(!categoryKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/category/?teamKey=`+teamKey+'&categoryKey='+categoryKey, {
        withCredentials: true,
    });
    return data.data.data;
}

export const getIdeas = async (teamKey: string, categoryKey:string, ideaKey: string|undefined): Promise<IdeaListItem[]|undefined> => {
    if(!teamKey || !categoryKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/idea/list?teamKey=`+teamKey+'&categoryKey='+categoryKey+(ideaKey?'&ideaKey='+ideaKey:''), {
        withCredentials: true,
    });
    return data.data.data;
}
export const getUnpublishedIdeas = async (teamKey: string, categoryKey:string, ideaKey: string|undefined): Promise<IdeaListItem[]|undefined> => {
    if(!teamKey || !categoryKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/idea/unpublished?teamKey=`+teamKey+'&categoryKey='+categoryKey+(ideaKey?'&ideaKey='+ideaKey:''), {
        withCredentials: true,
    });
    return data.data.data;
}
export const getHiddenIdeas = async (teamKey: string, categoryKey:string, ideaKey: string|undefined): Promise<IdeaListItem[]|undefined> => {
    if(!teamKey || !categoryKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/idea/hidden?teamKey=`+teamKey+'&categoryKey='+categoryKey+(ideaKey?'&ideaKey='+ideaKey:''), {
        withCredentials: true,
    });
    return data.data.data;
}
export const getIdea = async (teamKey: string, categoryKey:string, ideaKey: string): Promise<IdeaListItem|undefined> =>{
    if(!teamKey || !categoryKey || !ideaKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/idea/?teamKey=`+teamKey+'&categoryKey='+categoryKey+'&ideaKey='+ideaKey, {
        withCredentials: true,
    });
    return data.data.data;
}

export const publishIdea = async (teamKey: string, categoryKey:string, ideaKey: string) => {
    if(!teamKey || !categoryKey || !ideaKey){
        return undefined;
    }
    const data = await axios.post(`https://${hostName}/idea/publish?teamKey=${teamKey}&categoryKey=${categoryKey}&ideaKey=${ideaKey}`, null, {
        withCredentials: true,
    });
    return data.data.data;
}
export const rootParent = async (teamKey: string, categoryKey: string, ideaKey: string): Promise<IdeaListItem|undefined> =>{
    if(!teamKey || !ideaKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/idea/root?teamKey=${teamKey}&categoryKey=${categoryKey}&ideaKey=${ideaKey}`, {
        withCredentials: true,
    });
    return data.data.data;
}
export const ideaTree = async (teamKey: string, categoryKey: string, ideaKey: string): Promise<IdeaTree|undefined> =>{
    if(!teamKey || !ideaKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/idea/tree?teamKey=${teamKey}&categoryKey=${categoryKey}&ideaKey=${ideaKey}`, {
        withCredentials: true,
    });
    return data.data.data;
}

export const ideaIdTree = async (teamKey: string, categoryKey: string, ideaKey: string): Promise<IdeaIdTree|undefined> =>{
    if(!teamKey || !ideaKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/idea/tree/ids?teamKey=${teamKey}&categoryKey=${categoryKey}&ideaKey=${ideaKey}`, {
        withCredentials: true,
    });
    return data.data.data;
}

export const vote = async (teamKey: string, ideaKey: string, voteUp: boolean): Promise<IdeaListItem|undefined> =>{
    if(!teamKey || !ideaKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/idea/vote?teamKey=${teamKey}&ideaKey=${ideaKey}&voteUp=${voteUp}`, {
        withCredentials: true,
    });
    return data.data.data;
}

export const decide = async (teamKey: string, ideaKey: string, accepted: boolean): Promise<IdeaListItem|undefined> =>{
    if(!teamKey || !ideaKey){
        return undefined;
    }
    const data = await axios.post(`https://${hostName}/idea/decision?teamKey=${teamKey}&ideaKey=${ideaKey}&accepted=${accepted}`, {}, {
        withCredentials: true,
    });
    return data.data.data;
}
export const getInvite = async (inviteKey: string|undefined): Promise<InviteMeta|undefined|string> =>{
    if(!inviteKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/invite/get?inviteKey=${inviteKey}`, {
        withCredentials: true,
    });
    return data.data.data;
}
export const viewInvite = async (teamKey: string|undefined,inviteKey: string|undefined): Promise<InviteMeta|undefined|string> =>{
    if(!inviteKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/invite/view?teamKey=${teamKey}&inviteKey=${inviteKey}`, {
        withCredentials: true,
    });
    return data.data.data;
}
export const acceptInvite = async (inviteKey: string|undefined): Promise<TeamDE|undefined|string> => {
    if(!inviteKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/invite/accept?inviteKey=${inviteKey}`, {
        withCredentials: true,
    });
    return data.data.data;
}
export const deactivateInvite = async (inviteKey: string, teamKey: string): Promise<InviteDE|undefined|string> => {
    if(!inviteKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/invite/deactivate?inviteKey=${inviteKey}&teamKey=${teamKey}`, {
        withCredentials: true,
    });
    return data.data.data;
}

export const activateInvite = async (inviteKey: string, teamKey: string): Promise<InviteDE|undefined|string> => {
    if(!inviteKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/invite/activate?inviteKey=${inviteKey}&teamKey=${teamKey}`, {
        withCredentials: true,
    });
    return data.data.data;
}

export const acceptedInvite = async (inviteKey: string, teamKey: string): Promise<{user: UserDE, inviteAccept: Connection}|undefined|string> => {
    if(!inviteKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/invite/accepted?inviteKey=${inviteKey}&teamKey=${teamKey}`, {
        withCredentials: true,
    });
    return data.data.data;
}
export const listInvites = async (teamKey: string): Promise<InviteMeta[]|undefined|string> => {
    if(!teamKey){
        return undefined;
    }
    const data = await axios.get(`https://${hostName}/invite/list?teamKey=${teamKey}`, {
        withCredentials: true,
    });
    return data.data.data;
}

export const createInvite = async (teamKey: string, roleName: string, invite: IInvite): Promise<InviteDE|undefined|string> => {
    if(!teamKey){
        return undefined;
    }
    const data = await axios.post(`https://${hostName}/invite/create?teamKey=${teamKey}&roleName=${roleName}`, invite, {
        withCredentials: true,
    });
    return data.data.data;
}
export const editInvite = async (teamKey: string, inviteKey: string, invite: IInvite): Promise<InviteDE|undefined|string> => {
    if(!teamKey){
        return undefined;
    }
    const data = await axios.post(`https://${hostName}/invite/update?teamKey=${teamKey}&inviteKey=${inviteKey}`, invite, {
        withCredentials: true,
    });
    return data.data.data;
}

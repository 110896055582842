import RoleDE from "./API/RoleDE";
import {RoleAndPermissionTree} from "../stores/team.reducer";

export interface Node {
    directSub: boolean;
    allSub: boolean;
    has: boolean;
    nodes: {[key:string]: Node };
}

export class PermissionTree {

    static build(roles?: Set<RoleDE> | RoleDE): Node {
        const root: Node = {
            allSub: false,
            directSub: false,
            has: false,
            nodes: {}
        };
        if (roles instanceof Set) {
            roles.forEach(role => {
                role.data.permissions.forEach(permission => {
                    this.add(root, permission);
                });
            });
        } else if (roles) {
            for (let permission of roles.data.permissions) {
                this.add(root, permission);
            }
        }
        return root;
    }

    private static upsert(node: Node, word: string): Node | null {
        if(word === "**") {
            node.allSub = true;
            return null;
        }
        if(word === "*") {
            node.directSub = true;
            return null;
        }
        let n: Node | undefined = node.nodes[word];
        if(n) {
            return n;
        }
        let newNode: Node = {
            allSub: false,
            directSub: false,
            has: false,
            nodes: {}
        };
        node.nodes[word] = newNode;
        return newNode;
    }

    private static hasPermissionInner(node: Node, words: Array<string> ): boolean {
        if(words.length === 0 && node.has) return true;
        if(words.length === 1 && node.directSub) return true;
        if(words.length >= 1 && node.allSub) return true;
        if(words.length === 0) return false;

        let n: Node | undefined = node.nodes[words.shift()!];
        if(n) {
            return this.hasPermissionInner(n, words);
        }
        return false;
    }

    static hasPerm(root: Node, permission: string): boolean {
        let words: Array<string> = permission.split(".");
        return this.hasPermissionInner(root, words);
    }
    static hasOnePermissions(root: Node, permissions: string[]): boolean{
        for (let i = 0; i < permissions.length; i++)
            if(this.hasPerm(root, permissions[i]))
                return true;
        return false;
    }

    static add(root: Node, permissionString: string): void {
        let items: string[] = permissionString.split(".");
        let currentNode: Node | null = root;
        items.forEach(item => {
            if (currentNode) {
                currentNode = this.upsert(currentNode, item);
            }
        });
        if (currentNode) currentNode.has = true;
    }
}

export const filterRoles = (roles: RoleAndPermissionTree[], perms: string[]): RoleAndPermissionTree[] => {
    return roles.filter(role=>{
        for (let i = 0; i < perms.length; i++)
            if(PermissionTree.hasPerm(role.permissionTreeRoot, perms[i]))
                return true;
        return false;
    });
}

import {configureStore} from '@reduxjs/toolkit'
import profileReducer from "./profile.reducer";
import teamsReducer from "./team.reducer";
import categoryReducer from "./category.reducer";
import globalReducer from "./global.reducer";
import ideaReducer from "./idea.reducer";

const globalStore = configureStore({
    reducer: {
        profile: profileReducer,
        teams: teamsReducer,
        categories: categoryReducer,
        globals: globalReducer,
        ideas: ideaReducer,
    },
})

export type GlobalState = ReturnType<typeof globalStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type GlobalDispatch = typeof globalStore.dispatch;

export default globalStore;
